import React, {useEffect, useState} from "react";
import Select from "react-select";
import CountUp from "react-countup";
import {
  doctor_dashboard_01,
  doctor_dashboard_02,
  doctor_dashboard_03,
  doctor_dashboard_04,
  morning_img_02,
  user001
} from "../../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import PatientByDepartment from "./PatientsByDepartment"
import PatientsByGender from "./PatientByGender";
import {dashboard} from "../../../services/dahsboard/dashboard";
import {connect} from "react-redux";

const Doctor_Dashboard = ({user, authenticated,token, clinic_id, client_id}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const [doctorDashboardData, setDoctorDashboardData] = useState({});
  const [showPage, setShowPage] = useState(false);

 /*  const [options, setOptions] = useState([
    { value: 1, label: "This Week" },
    { value: 2, label: "Last Week" },
    { value: 3, label: "This Month" },
    { value: 4, label: "Last Month" },
  ]); */
  // eslint-disable-next-line no-unused-vars
  const [year, setyear] = useState([
   /* { value: 1, label: "2022" },
    { value: 2, label: "2021" },
    { value: 3, label: "2020" },
    { value: 4, label: "2019" },*/
  ]);

  const fetchData = async () => {
    const resp = await dashboard.getDoctorDashboardData(user, user.user_id);
    if (resp) {
      setDoctorDashboardData(resp);
      setShowPage(true);
    }
  };

  useEffect(() => {
    console.log(user);
    if(user.user_id) {
      fetchData();
    }
  }, [user]);

  return (
    <>
      {(showPage &&
        <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Doctor Dashboard</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                    Welcome back, <span>{user.role === 'DeepFactsSuperAdmin' ? "Vamsi Karatam" : user?.name}!</span>
                    </h2>
                    {/*<p>Have a nice day at work</p>*/}
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_02} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="doctor-list-blk">
              <div className="row">
                <div className="col-xl-3 col-md-6">
                  <div className="doctor-widget border-right-bg">
                    <div className="doctor-box-icon flex-shrink-0">
                      <img src={doctor_dashboard_01} alt="" />
                    </div>
                    <div className="doctor-content dash-count flex-grow-1">
                      <h4>
                        <CountUp delay={0.4} end={doctorDashboardData.rpm_patient_count} duration={0.6} />
                        <span className="counter-up">/125</span>
                        <span className="status-green">+40%</span>
                      </h4>
                      <h5>RPM Patients</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="doctor-widget border-right-bg">
                    <div className="doctor-box-icon flex-shrink-0">
                      <img src={doctor_dashboard_02} alt="" />
                    </div>
                    <div className="doctor-content dash-count flex-grow-1">
                      <h4>
                        <CountUp delay={0.4} end={doctorDashboardData.active_patients} duration={0.6} />
                        <span className="counter-up">/125</span>
                        <span className="status-green">+40%</span>
                      </h4>
                      <h5>Active Patients</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="doctor-widget border-right-bg">
                    <div className="doctor-box-icon flex-shrink-0">
                      <img src={doctor_dashboard_03} alt="" />
                    </div>
                    <div className="doctor-content dash-count flex-grow-1">
                      <h4>
                        <CountUp delay={0.4} end={doctorDashboardData.inactive_patients} duration={0.6} />
                        <span className="counter-up">/125</span>
                        <span className="status-green">+40%</span>
                      </h4>
                      <h5>Inactive Patients</h5>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="doctor-widget">
                    <div className="doctor-box-icon flex-shrink-0">
                      <img src={doctor_dashboard_04} alt="" />
                    </div>
                    <div className="doctor-content dash-count flex-grow-1">
                      <h4>
                        $ <CountUp delay={0.4} end={doctorDashboardData.earnings.monthly} duration={0.6} />
                        <span className="status-green">+50%</span>
                      </h4>
                      <h5>Earnings</h5>
                      {/* <p>
                        <span className="passive-view">
                          <i className="feather-arrow-up-right me-1" />
                          30%
                        </span>{" "}
                        vs last month
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 col-lg-6 col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <div className="chart-title patient-visit">
                      <h4>Patient Visit by Gender</h4>
                      <div>
                        <ul className="nav chat-user-total">
                          <li>
                            <i
                              className="fa fa-circle current-users"
                              aria-hidden="true"
                            />
                            Male {doctorDashboardData.patient_visits_by_gender.male_percent}%
                          </li>
                          <li>
                            <i
                              className="fa fa-circle old-users"
                              aria-hidden="true"
                            />{" "}
                            Female {doctorDashboardData.patient_visits_by_gender.female_percent}%
                          </li>
                        </ul>
                      </div>
                      <div className="form-group mb-0">
                        <Select
                          className="custom-react-select"
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={doctorDashboardData.patient_visits_by_gender.months}
                          id="search-commodity"
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                              boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                              '&:hover': {
                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                              },
                              borderRadius: '10px',
                              fontSize: "14px",
                              minHeight: "45px",
                            }),
                            dropdownIndicator: (base, state) => ({
                              ...base,
                              transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                              transition: '250ms',
                              width: '35px',
                              height: '35px',
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div id="patient-chart" />
                    <PatientByDepartment chartData={doctorDashboardData.patients_by_department} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6 col-xl-3 d-flex">
                <div className="card">
                  <div className="card-body">
                    <div className="chart-title">
                      <h4>Patient By Department</h4>
                    </div>
                    <div id="donut-chart-dash" className="chart-user-icon">
                      <PatientsByGender chartData={doctorDashboardData.patients_by_gender} />
                      <img src={user001} alt="#" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
            </div>
          </div>
        </div>
      </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.userData,
  authenticated: state.auth.authenticated,
  token: state.auth.token,
  client_id: state.auth.client_id,
  clinic_id: state.auth.clinic_id
});
export default connect(mapStateToProps)(Doctor_Dashboard);
