// hooks/usePostalCode.js
import { useState } from 'react';

const usePostalCode = () => {
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  
  const handlePincodeChange = async (pincode, setFormData) => {
    setFormData((prevData) => ({ ...prevData, pin_code: pincode }));

    if (pincode.length === 6) { // Assuming pincode is 6 digits long
      try {
        const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
        const data = await response.json();

        if (data[0].Status === "Success") {
          const postOffice = data[0].PostOffice[0];
          const city = postOffice?.District || '';
          const state = postOffice?.State || '';
          const country = postOffice?.Country || '';

          setFormData((prevData) => ({
            ...prevData,
            city,
            state,
            country
          }));

          setCityOptions([{ label: city, value: city }]);
          setStateOptions([{ label: state, value: state }]);
          setCountryOptions([{ label: country, value: country }]);
        } else {
          // Reset the fields if the pincode is invalid
          setFormData((prevData) => ({ ...prevData, city: '', state: '', country: '' }));
          resetOptions();
        }
      } catch (error) {
        console.error("Error fetching the location data: ", error);
        setFormData((prevData) => ({ ...prevData, city: '', state: '', country: '' }));
        resetOptions();
      }
    } else {
      setFormData((prevData) => ({ ...prevData, city: '', state: '', country: '' }));
      resetOptions();
    }
  };

  const resetOptions = () => {
    setCityOptions([]);
    setStateOptions([]);
    setCountryOptions([]);
  };

  return {
    handlePincodeChange,
    cityOptions,
    stateOptions,
    countryOptions,
    handleCityChange: (selectedOption, setFormData) => {
      setFormData((prevData) => ({ ...prevData, city: selectedOption ? selectedOption.value : '' }));
    },
    handleStateChange: (selectedOption, setFormData) => {
      setFormData((prevData) => ({ ...prevData, state: selectedOption ? selectedOption.value : '' }));
    },
    handleCountryChange: (selectedOption, setFormData) => {
      setFormData((prevData) => ({ ...prevData, country: selectedOption ? selectedOption.value : '' }));
    }
  };
};

export default usePostalCode;
