// index.js or main entry file
import React from "react";
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/select2.min.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import { Provider } from "react-redux";
import { store } from "./utils/redux/store";
import App from "./App";
import { DeviceProvider } from './context/DeviceContext';
import { NurseProvider } from './context/NurseContext';
import { DoctorProvider } from "./context/DoctorContext.js";
import { PatientProvider } from "./context/PatientContext.js";
import { ClientProvider } from "./context/ClientContext.js";
import { ClinicProvider } from "./context/ClinicContext.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <DeviceProvider>
      <NurseProvider>
        <ClientProvider>
          <ClinicProvider>
            <DoctorProvider>
            <PatientProvider>
                <App />
                </PatientProvider>
            </DoctorProvider>
          </ClinicProvider>
        </ClientProvider>
      </NurseProvider>
    </DeviceProvider>
  </Provider>
);