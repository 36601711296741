import React, { useState, useEffect } from 'react';
import { imguser } from '../imagepath';

const ClinicProfile = ({ clinicData }) => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        address: '',
        city: '',
        state: '',
        country: ''
    });

    useEffect(() => {
        if (clinicData) {
            // Pre-fill form with clinic data when the component mounts
            setFormData({
                name: clinicData.name || '',
                mobile: clinicData.mobile || '',
                email: clinicData.email || '',
                address: clinicData.address || '',
                city: clinicData.city || '',
                state: clinicData.state || '',
                country: clinicData.country || ''
            });
        }
    }, [clinicData]);

    return (
        <form>
            <div className="card-box">
                <h3 className="card-title">{clinicData.name}</h3>
                <div className="row">
                    <div className="col-md-12">
                        <div className="profile-img-wrap">
                            <img className="inline-block" src={imguser} alt="user" />
                            <div className="fileupload btn">
                                <span className="btn-text">edit</span>
                                <input className="upload" type="file" />
                            </div>
                        </div>
                        <div className="profile-basic">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">Client Name</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            defaultValue="John"
                                            value={clinicData.client_name}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">License Number</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            defaultValue="Doe"
                                            value={clinicData.license_number}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">Mobile Number</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            defaultValue="Doe"
                                            value={clinicData.mobile}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">Email</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            defaultValue="Doe"
                                            value={clinicData.email}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-box">
                <h3 className="card-title">Address Information</h3>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group local-forms">
                            <label className="focus-label">Address</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="4487 Snowbird Lane"
                                value={clinicData.address}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group local-forms">
                            <label className="focus-label">State</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="New York"
                                value={clinicData.state}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group local-forms">
                            <label className="focus-label">Country</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="United States"
                                value={clinicData.country}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group local-forms">
                            <label className="focus-label">Pin Code</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={clinicData.pin_code}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-box">
                <h3 className="card-title">Bank Information</h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">Account Holder Name</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Oxford University"
                                value={clinicData.account_holder_name}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">Account Number</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Computer Science"
                                value={clinicData.account_number}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">IFSC Code</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Computer Science"
                                value={clinicData.ifsc_code}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">Bank Branch</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Computer Science"
                                value={clinicData.bank_branch}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ClinicProfile;
