import React,  { useState, useEffect } from "react";
import { cameraicon, doctor, imagesend, menuicon16, profilebg, profileuser01, } from "../imagepath";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

const PatientsProfile = () => {
  const [activeTab, setActiveTab] = useState('general'); // Set the default active tab

  return (
    <div>
      <>
        {/*<Header /> <Sidebar id="menu-item4" id1="menu-items4" activeClassName="patient-profile" />*/}
        <>
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Patients </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">
                        Patient Profile
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="row mt-4">
                <div className="col-xl-3 col-md-4">
                  <div className="widget settings-menu setting-list-menu">
                    <ul className='mb-0'>
                      <li className={`nav-item ${activeTab === 'general' ? 'active' : ''}`}>
                        <span
                          className="nav-link"
                          onClick={() => setActiveTab('general')}
                        >
                          <i className="fa fa-cog me-2" /> <span>General Settings</span>
                        </span>
                      </li>
                      <li className={`nav-item ${activeTab === 'social' ? 'active' : ''}`}>
                        <span
                          className="nav-link"
                          onClick={() => setActiveTab('social')}
                        >
                          <i className="fa fa-bookmark me-2" /> <span>Social Media Settings</span>
                        </span>
                      </li>
                      <li className={`nav-item ${activeTab === 'bank' ? 'active' : ''}`}>
                        <span
                          className="nav-link"
                          onClick={() => setActiveTab('bank')}
                        >
                          <i className="fas fa-university me-2" /> <span>Bank Settings</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-9 col-md-8">
                  {activeTab === 'general' && (
                    <div className="card invoices-settings-card">
                      <div className="card-header px-3">
                        <h5 className="card-title">General Settings</h5>
                      </div>
                      <div className="card-body">
                        {/* Form */}
                        <form action="#" className="invoices-settings-form">
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                             Patient Name
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                            Date of Birth
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                         
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                             Email
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="phone"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Mobile
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="+91"
                              />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="phone"
                              className="col-sm-3 col-form-label input-label"
                            >
                             Secondary Mobile
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="+91"
                              />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Gender
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Blood Group
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Relation
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Patient UID
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Doctor Name
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Height
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Weight
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                             Symptoms
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Address
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="zipcode"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Postal Code
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              City
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Country
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              State
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="invoice-setting-btn text-end" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px', marginBottom: '10px' }}> <button type="submit" className="btn cancel-btn me-2">
                            Cancel
                          </button>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                        {/* /Form */}
                      </div>
                    </div>

                  )}

                  {activeTab === 'social' && (
                    <div className="card invoices-settings-card">
                      <div className="card-header px-3">
                        <h5 className="card-title">Social Media Settings</h5>
                      </div>
                      <div className="card-body">
                        <form action="#" className="invoices-settings-form">
                          {/* Add your social media settings form fields here */}
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Facebook ID
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Twitter ID
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Instagram ID
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              LinkedIn ID
                            </label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          {/* Add more fields as needed */}
                          <div className="invoice-setting-btn text-end" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px', marginBottom: '10px' }}> <button type="submit" className="btn cancel-btn me-2">
                            Cancel
                          </button>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                  {activeTab === 'bank' && (
                    <div className="card invoices-settings-card">
                      <div className="card-header px-3">
                        <h5 className="card-title">Bank Settings</h5>
                      </div>
                      <div className="card-body">
                        <form action="#" className="invoices-settings-form">
                          {/* Add your bank settings form fields here */}
                          <div className="row align-items-center form-group">
                            <label htmlFor="accountHolderName" className="col-sm-3 col-form-label input-label">Pan Number</label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label htmlFor="accountHolderName" className="col-sm-3 col-form-label input-label">Account Holder Name</label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label htmlFor="accountHolderName" className="col-sm-3 col-form-label input-label">Account Number</label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label htmlFor="accountHolderName" className="col-sm-3 col-form-label input-label">Bank Branch</label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label htmlFor="accountHolderName" className="col-sm-3 col-form-label input-label">IFSC Code</label>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          {/* Add more fields as needed */}
                          <div className="invoice-setting-btn text-end" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px', marginBottom: '10px' }}> <button type="submit" className="btn cancel-btn me-2">
                            Cancel
                          </button>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Notifications-Box */}
          </div>
          <div
            id="delete_patient"
            className="modal fade delete-modal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img src={imagesend} alt="" width={50} height={46} />
                  <h3>Are you sure want to delete this ?</h3>
                  <div className="m-t-20">
                    {" "}
                    <Link
                      to="#"
                      className="btn btn-white me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                    <button type="submit" className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </div>
  );
};

export default PatientsProfile;
