import Header from "./Header";
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";

const Layout = ({ user, authenticated }) => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (authenticated && (location.pathname === '/' || location.pathname === '/login' || location.pathname === '')) {
            switch (user.role) {
                case 'DeepFactsSuperAdmin':
                case 'DeepFactsAdmin':
                    navigate('/deepfacts-dashboard');
                    break;
                case 'ClientSuperAdmin':
                case 'ClientAdmin':
                    navigate('/client-dashboard');
                    break;
                case 'ClinicAdmin':
                    navigate('/clinic-dashboard');
                    break;
                case 'Doctor':
                    navigate('/doctor-dashboard');
                    break;
                case 'Nurse':
                    navigate('/add-patient');
                    // case 'Patient':
                    //     navigate('/patient-dashboard');
                    break;
                default:
                    navigate('/deepfacts-dashboard');
                    break;
            }
        }
        else if (location.pathname === '/verify_email') {
            return; // Do not redirect
        }
    }, [authenticated, user, location.pathname]);
    return <>
        {(authenticated &&
            <>
                <Header user={user} authenticated={authenticated} />
                <Sidebar
                    id="menu-item1"
                    id1="menu-items"
                    currentPath={location.pathname}
                    user={user}
                    authenticated={authenticated}
                />
                <>
                    <Outlet />
                </>
            </>
        )}
        {/*(!authenticated && <Outlet />)*/}
    </>;
}

const mapStateToProps = (state) => ({
    user: state.auth.userData,
    authenticated: state.auth.authenticated
});

export default connect(mapStateToProps)(Layout);

