import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {useEffect} from "react";

const ErrorComponent = ({showPopup, message, hideError}) => {

    useEffect(()=>{
        console.log(message);
    },[message]);

    useEffect(()=>{
       console.log(showPopup);
    },[showPopup]);

    return (
        <Modal show={showPopup}>
            <Modal.Header closeButton={false}>
                <Modal.Title>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{hideError()}}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        message: state.error.message,
        showPopup: state.error.showPopup
    };
};

const mapDispatchToProps = (dispatch) => ({
    hideError: () => dispatch({type: 'HIDE_ERROR'})
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorComponent);