import React, { createContext, useContext, useState } from 'react';

// Create a context
const DoctorContext = createContext();

// Create a provider component
export const DoctorProvider = ({ children }) => {
  const [doctorId, setDoctorId] = useState(null);

  return (
    <DoctorContext.Provider value={{ doctorId, setDoctorId }}>
      {children}
    </DoctorContext.Provider>
  );
};

// Custom hook to use the DoctorContext
export const useDoctor = () => {
  return useContext(DoctorContext);
};

