import React, { useEffect, useState } from 'react';
import { Table } from "antd";
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import patientApi from '../../services/ClientServices/PatientRoutes';
import { onShowSizeChange, itemRender } from '../Pagination'
import { plusicon, refreshicon, searchnormal, error02, dangericon } from '../imagepath';
import StatusButton from '../../statusbutton';
import { connect } from "react-redux";

const RpmDetails = ({ clinic_id }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [error, setError] = useState(null);

  const fetchAssignedPatients = async () => {
    try {
      const response = await patientApi.getAssignedPatients();
      setDataSource(response.assign_patients || []);
      setError(null);  
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setError("500 Error");
      } else {
        setError("An error occurred while fetching patients.");
      }
      setDataSource([]);  // Clear the data source if there's an error
    }
  };

  useEffect(() => {
    if (clinic_id) {
      fetchAssignedPatients()
    } else {
      setError("No clinic is selected, please select a clinic to proceed further.");
    }
  }, [clinic_id]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Clinic Name",
      dataIndex: "clinic_name",
      sorter: (a, b) => a.clinic_name.localeCompare(b.clinic_name),
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
      sorter: (a, b) => a.doctor_name.localeCompare(b.doctor_name),
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
    },
    {
      title: "Block",
      dataIndex: "block",
      sorter: (a, b) => a.block.localeCompare(b.block),
    },
    {
      title: "Ward",
      dataIndex: "ward",
      sorter: (a, b) => a.ward.localeCompare(b.ward),
    },
    {
      title: "Bed",
      dataIndex: "bed",
      sorter: (a, b) => a.bed - b.bed,
    },
    {
      title: "Floor",
      dataIndex: "floor",
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Room",
      dataIndex: "room",
      sorter: (a, b) => a.room - b.room,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => (
        <StatusButton status={status} onPatientStatusClick={() => console.log(`Status: ${status}`)} />
      ),
    },
  ];


  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patient Monitoring</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Assigned Patient List</li>
                </ul>
              </div>
            </div>
          </div>
          {error === "500 Error" ? (
            <div className="main-wrapper error-wrapper">
              <div className="error-box">
                <img className="img-fluid" src={error02} alt="Service Unavailable" />
                <h3>
                  <img className="img-fluid mb-0" src={dangericon} alt="Danger Icon" />{" "}
                  Service Unavailable
                </h3>
                <p>Sorry, an error has occurred, and we're working to fix the problem.</p>
                <Link to="/" className="btn btn-primary go-home">
                  Back to Home
                </Link>
              </div>
            </div>
          ) : error === "No clinic is selected, please select a clinic to proceed further." ? (
            <div className="d-inline-block" style={{ textAlign: 'center' }}>
              <div
                className="alert alert-danger"
                role="alert"
                style={{ display: error ? 'block' : 'none', marginBottom: '10px' }}
              >
                {error}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Assigned Patient List</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <form>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search here"
                                  />
                                  <Link className="btn">
                                    <img
                                      src={searchnormal}
                                      alt="#"
                                    />
                                  </Link>
                                </form>
                              </div>
                              <div className="add-group">
                                <Link
                                  to="/assign-patient"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  to="#"
                                  onClick={fetchAssignedPatients}
                                  className="btn btn-primary doctor-refresh ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        pagination={{
                          total: dataSource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={dataSource}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  clinic_id: state.auth.clinic_id
});

export default connect(mapStateToProps)(RpmDetails);
