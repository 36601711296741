
const storageUtil = {
  setToStore: (key, data, stringify = true) => {
    localStorage.setItem(key, stringify ? JSON.stringify(data) : data);
  },

  getFromStore: (key, unStringify = true) => {
    const data = localStorage.getItem(key);
    if (data) return unStringify ? JSON.parse(data) : data;
  },
  
  removeFromStore: (key) => {
    localStorage.removeItem(key);
  }
}

export default storageUtil;