import {
    dashboard,
    doctor,
    menuitem,
    menuicon06,
    menuicon09,
    menuicon08,
    menuicon14,
    menuicon15,
    menuicon16,
    patients,
    sidemenu
} from "../components/imagepath";

export const roles = ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin']
export const menuItems = [
    {
        "text": "",
        "url": null,
        "image": null,
        "hasSubmenu": false,
        "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
        "subMenu": [],
        "menuName": "Main"
    },
    {
        "text": "Dashboard",
        "url": "",
        "image": dashboard,
        "hasSubmenu": true,
        "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
        "subMenu": [
            {
                "text": "RPM Dashboard",
                "url": "/admin-dashboard",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "RpmDashboard"
            },
            {
                "text": "DeepFacts Dashboard",
                "url": "/deepfacts-dashboard",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin'],
                "subMenu": [],
                "menuName": "DeepFactsDashboard"
            },
            {
                "text": "Client Dashboard",
                "url": "/client-dashboard",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin'],
                "subMenu": [],
                "menuName": "ClientDashboard"
            },
            {
                "text": "Clinic Dashboard",
                "url": "/clinic-dashboard",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "ClinicDashboard"
            },
            {
                "text": "Doctor Dashboard",
                "url": "/doctor-dashboard",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin','ClinicAdmin','Doctor'],
                "subMenu": [],
                "menuName": "DoctorDashboard"
            },
        ],
        "menuName": "Dashboard"
    },
    {
        "text": "Clients",
        "url": "",
        "image": menuicon09,
        "hasSubmenu": true,
        "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin'],
        "subMenu": [
            {
                "text": "Client List",
                "url": "/client-list",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin'],
                "subMenu": [],
                "menuName": "ClientList"
            },
            {
                "text": "Add Client",
                "url": "/add-client",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin'],
                "subMenu": [],
                "menuName": "AddClient"
            },
            // {
            //     "text": "Edit Client",
            //     "url": "/edit-client",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','ClientSuperAdmin'],
            //     "subMenu": [],
            //     "menuName": "EditClient"
            // },
            // {
            //     "text": "Client Profile",
            //     "url": "/client-profile",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin'],
            //     "subMenu": [],
            //     "menuName": "ClientProfile"
            // }
        ],
        "menuName": "Clients"
    },
    {
        "text": "Clinics",
        "url": "",
        "image": menuicon06,
        "hasSubmenu": true,
        "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin'],
        "subMenu": [
            {
                "text": "Clinic List",
                "url": "/clinic-list",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin'],
                "subMenu": [],
                "menuName": "ClinicList"
            },
            {
                "text": "Add Clinic",
                "url": "/add-clinic",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin'],
                "subMenu": [],
                "menuName": "AddClinic"
            },
            // {
            //     "text": "Edit Clinic",
            //     "url": "/edit-clinic",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','ClientSuperAdmin','ClientAdmin'],
            //     "subMenu": [],
            //     "menuName": "EditClinic"
            // },
            // {
            //     "text": "Clinic Profile",
            //     "url": "/clinic-profile",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
            //     "subMenu": [],
            //     "menuName": "ClinicProfile"
            // }
        ],
        "menuName": "Clinics"
    },
    {
        "text": "Doctors",
        "url": "",
        "image": doctor,
        "hasSubmenu": true,
        "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin' ,'ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
        "subMenu": [
            {
                "text": "Doctor List",
                "url": "/doctorlist",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "DoctorList"
            },
            {
                "text": "Add Doctor",
                "url": "/add-doctor",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "AddDoctor"
            },
            // {
            //     "text": "Edit Doctor",
            //     "url": "/editdoctor",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','ClientSuperAdmin','ClinicAdmin','Doctor'],
            //     "subMenu": [],
            //     "menuName": "EditDoctor"
            // },
            // {
            //     "text": "Doctor Profile",
            //     "url": "/doctorprofile",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
            //     "subMenu": [],
            //     "menuName": "DoctorProfile"
            // }
        ],
        "menuName": "Doctors"
    },
    {
        "text": "Nurses",
        "url": "",
        "image": menuicon08,
        "hasSubmenu": true,
        "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
        "subMenu": [
            {
                "text": "Nurse List",
                "url": "/nurse-list",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "NurseList"
            },
            {
                "text": "Add Nurse",
                "url": "/add-nurse",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "AddNurse"
            },
            // {
            //     "text": "Edit Nurse",
            //     "url": "/edit-nurse",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','Nurse','ClientSuperAdmin','ClinicAdmin'],
            //     "subMenu": [],
            //     "menuName": "EditNurse"
            // },
            // {
            //     "text": "Nurse Profile",
            //     "url": "/nurse-profile",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Nurse','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
            //     "subMenu": [],
            //     "menuName": "NurseProfile"
            // }
        ],
        "menuName": "Nurses"
    },
    {
        "text": "Patients",
        "url": "",
        "image": patients,
        "hasSubmenu": true,
        "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
        "subMenu": [
            {
                "text": "Patient List",
                "url": "/patient-list",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "PatientList"
            },
            {
                "text": "Add Patients",
                "url": "/add-patient",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "AddPatients"
            },
            // {
            //     "text": "Edit Patients",
            //     "url": "/edit-patient",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','ClientSuperAdmin','ClinicAdmin'],
            //     "subMenu": [],
            //     "menuName": "EditPatients"
            // },
            // {
            //     "text": "Patients Profile",
            //     "url": "/patient-profile",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
            //     "subMenu": [],
            //     "menuName": "PatientsProfile"
            // }
        ],
        "menuName": "Patients"
    },
    {
        "text": "Devices",
        "url": "",
        "image":sidemenu,
        "hasSubmenu": true,
        "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin'],
        "subMenu": [
            {
                "text": "Device List",
                "url": "/device-list",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin'],
                "subMenu": [],
                "menuName": "DeviceList"
            },
            {
                "text": "Add Device",
                "url": "/add-device",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin'],
                "subMenu": [],
                "menuName": "AddDevice"
            }
            // {
            //     "text": "Edit Device",
            //     "url": "/edit-device",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
            //     "subMenu": [],
            //     "menuName": "EditDevice"
            // }
        ],
        "menuName": "Devices"
    },
    {
        "text": "RPM Details",
        "url": "",
        "image": menuicon15,
        "hasSubmenu": true,
        "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
        "subMenu": [
            {
                "text": "Assigned Patient List",
                "url": "/RPM-details",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "RPM-details"
            },
            {
                "text": "Assign Patient",
                "url": "/assign-patient",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "assign-patient"
            },
        ],
        "menuName": "RPMDetails"
    },
    {
        "text": "Settings",
        "url": "",
        "image": menuicon16,
        "hasSubmenu": true,
        "enabledForRoles": ['Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
        "subMenu": [
            // {
            //     "text": "User Settings",
            //     "url": "/settings",
            //     "image": null,
            //     "hasSubmenu": false,
            //     "enabledForRoles": ['DeepFactsSuperAdmin','DeepFactsAdmin','Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
            //     "subMenu": [],
            //     "menuName": "UserSettings"
            // },
            {
                "text": "Profile",
                "url": "/profile",
                "image": null,
                "hasSubmenu": false,
                "enabledForRoles": ['Doctor','Nurse','Patient','ClientSuperAdmin','ClientAdmin','ClinicAdmin'],
                "subMenu": [],
                "menuName": "profile"
            }
        ],
        "menuName": "Settings"
    }
];