import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import { login02, loginlogo } from '../../imagepath';
import { loginicon01 } from '../../imagepath';
import { loginicon02 } from '../../imagepath';
import { loginicon03 } from '../../imagepath';
import {requestOtp, updatePassword, verifyOtp} from "../../../services/userService";
import {store} from "../../../utils/redux/store";
import proRithmLogo from "../../../../src/assets/img/proRithm.png";


const ForgotPassword = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState(null);
    const [otp, setOtp] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [error, setError] = useState(null);
    const [showOtp, setShowOtp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    const getOtp = async () => {
        if(mobileNumber && mobileNumber.length === 10) {
            console.log(mobileNumber);
            setError(null);
            const resp = await requestOtp(mobileNumber);
            if(resp) {
                setShowOtp(true);
                // store.dispatch({type: 'NOTIFY', data: resp.message});
            } else {
                setError('Please enter valid mobile number');
            }
        } else {
            setError('Please enter valid mobile number');
        }
    }

    const submitOtp = async () => {
        if(otp && otp.length === 6) {
            setError(null);
            const resp = await verifyOtp({otp, username: mobileNumber});
            if(resp) {
                setShowOtp(false);
                setShowPassword(true);
                // store.dispatch({type: 'NOTIFY', data: resp.message});
                setToken(resp.token)
            } else {
                setError('Please enter valid otp');
            }
        } else {
            setError('Please enter valid otp');
        }
    }

    const submitPassword = async () => {
        if(password && confirmPassword && password === confirmPassword) {
            setError(null);
            const resp = await updatePassword(token,{new_password: password, confirm_password: confirmPassword});
            if(resp) {
                // store.dispatch({type: 'NOTIFY', data: resp.message});
                setShowPassword(false);
                setShowOtp(false);
                console.log('navigating');
                navigate('/login');
            }
        } else if(password && confirmPassword && password !== confirmPassword) {
            setError('Passwords doesn\'t match');
        } else {
            setError('Please enter valid password');
        }
    }

    return (
        <div>
            <div className="main-wrapper login-body">
                <div className="container-fluid px-0">
                    <div className="row">
                        {/* Login logo */}
                        <div className="col-lg-6 login-wrap">
                            <div className="login-sec">
                                <div className="log-img">
                                    <img
                                        className="img-fluid"
                                        src={login02}
                                        alt="Logo"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* /Login logo */}
                        {/* Login Content */}
                        <div className="col-lg-6 login-wrap-bg">
                            <div className="login-wrapper">
                                <div className="loginbox">
                                    <div className="login-right">
                                        <div className="login-right-wrap">
                                            <div className="account-logo">
                                              <Link to="/">
                                                    <img src={proRithmLogo}  width={165} height={45}  alt="#"/>
                                                </Link>
                                            </div>
                                            <h2>Reset Password</h2>
                                            {/* Form */}
                                            {(!showOtp && !showPassword) && <>
                                            <form>
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Mobile Number <span className="login-danger">*</span>
                                                    </label>
                                                    <input className="form-control" type="text" maxLength={10} minLength={10} value={mobileNumber}
                                                           onChange={(e) => setMobileNumber(e.target.value)}/>
                                                    {error && <p className="login-danger">{error}</p>}
                                                </div>
                                                <div className="form-group login-btn">
                                                    <button className="btn btn-primary btn-block" type="button" onClick={getOtp}>
                                                        Reset Password
                                                    </button>
                                                </div>
                                            </form>
                                            </>}
                                            {(showOtp && !showPassword) && <>
                                                <form>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Enter OTP <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="number" value={otp}
                                                               onChange={(e) => setOtp(e.target.value)}/>
                                                        {error && <p className="login-danger">{error}</p>}
                                                    </div>
                                                    <div className="form-group login-btn">
                                                        <button className="btn btn-primary btn-block" type="button" onClick={submitOtp}>
                                                            Submit OTP
                                                        </button>
                                                    </div>
                                                </form>
                                            </>}
                                            {(!showOtp && showPassword) && <>
                                                <form>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            New Password <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="text" value={password}
                                                               onChange={(e) => setPassword(e.target.value)}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Confirm New Password <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="text" value={confirmPassword}
                                                               onChange={(e) => setConfirmPassword(e.target.value)}/>
                                                        {error && <p className="login-danger">{error}</p>}
                                                    </div>
                                                    <div className="form-group login-btn">
                                                        <button className="btn btn-primary btn-block" type="button"
                                                                onClick={submitPassword}>
                                                            Submit OTP
                                                        </button>
                                                    </div>
                                                </form>
                                            </>}
                                            {/* /Form */}
                                            <div className="next-sign">
                                                <p className="account-subtitle">
                                                    Go back to
                                                    <Link to="/login"> Login</Link>
                                                </p>
                                                {/* Social Login */}
                                                {/*<div className="social-login">
                                                  <  Link to="#">
                                                        <img src={loginicon01} alt="#"/>
                                                    </Link>
                                                  <  Link to="#">
                                                        <img src={loginicon02} alt="#"/>
                                                    </Link>
                                                  <  Link to="#">
                                                        <img src={loginicon03} alt="#"/>
                                                    </Link>
                                                </div>*/}
                                                {/* /Social Login */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /Login Content */}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ForgotPassword;
