import apiClient from '../apiservice';
import { store } from "../../utils/redux/store";

const clinicApi = {
  createClinic: async (clinicData, selectedClientId = null) => {
    try {
      const clientId = store.getState().auth.client_id || selectedClientId;
      const response = await apiClient.post(`/clients/${clientId}/add_clinic`, clinicData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },


  getClinics: async (selectedClientId = null) => {
    try {
      const clientId = store.getState().auth.client_id || selectedClientId;
      const response = await apiClient.get(`/clients/${clientId}/get_all_clinics`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  readClinic: async (clinicId, selectedClientId = null) => {
    try {
      const clientId = store.getState().auth.client_id || selectedClientId;
      const response = await apiClient.get(`/clients/${clientId}/clinics/${clinicId}/get_clinic`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateClinic: async (clinicId, clinicData, selectedClientId = null) => {
    try {
      const clientId = store.getState().auth.client_id || selectedClientId;
      const response = await apiClient.put(`/clients/${clientId}/clinics/${clinicId}/update`, clinicData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default clinicApi;
