import React, { createContext, useContext, useState } from 'react';

// Create a context
const ClientContext = createContext();

// Create a provider component
export const ClientProvider = ({ children }) => {
  const [clientId, setClientId] = useState(null);

  return (
    <ClientContext.Provider value={{ clientId, setClientId }}>
      {children}
    </ClientContext.Provider>
  );
};

// Custom hook to use the ClientContext
export const useClient = () => {
  return useContext(ClientContext);
};


