import React, { useState } from 'react';
import VerificationPopup from './VerficationPopup';

const StatusButton = ({
  status,
  clientId,
  clinicId,
  type,
  mobileNumber,
  isMobileVerified,
  isEmailVerified,
  userEmail,
  onStatusClick, onPatientStatusClick
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    if (status.toLowerCase() === 'active' || status.toLowerCase() === 'inactive' ) {
      setShowPopup(true); 
    }
    // If you want to call onStatusClick when the button is clicked
    if (onStatusClick) {
      onStatusClick(status); // Call the passed function
    }
    if (onPatientStatusClick) {
      onPatientStatusClick(status); // Call the passed function
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false); 
  };

  let statusClass = "";
  let statusText = "";

  switch (status.toLowerCase()) {
    case "active":
    case "assigned":
      statusClass = "status-green";
      statusText = "Active";
      break;
    case "unassigned":
    case "inactive":
      statusClass = "status-pink";
      statusText = "InActive";
      break;
    case "inventory":
      statusClass = "status-orange";
      statusText = "Inventory";
      break;
    default:
      statusClass = "status-grey";
      statusText = status;
  }

  return (
    <>
      <button className={`custom-badge ${statusClass} dropdown-toggle`} onClick={handleButtonClick} >
        {statusText}
      </button>

      {showPopup && (
        <VerificationPopup
          emailVerified={isEmailVerified} // Pass the prop directly
          mobileVerified={isMobileVerified} // Pass the prop directly
          onClose={handleClosePopup}
          clientId={clientId}
          clinicId={clinicId}
          type={type}
          mobileNumber={mobileNumber} // Pass mobile number to popup
          email={userEmail}
        />
      )}
    </>
  );
};

export default StatusButton;
