import React, { createContext, useContext, useState } from 'react';

// Create a context
const ClinicContext = createContext();

// Create a provider component
export const ClinicProvider = ({ children }) => {
  const [clinicId, setClinicId] = useState(null);

  return (
    <ClinicContext.Provider value={{ clinicId, setClinicId }}>
      {children}
    </ClinicContext.Provider>
  );
};

// Custom hook to use the ClinicContext
export const useClinic = () => {
  return useContext(ClinicContext);
};


