/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { menuItems } from "../../utils/menu";
import { logout } from "../imagepath";
import { connect } from "react-redux";


const Sidebar = (props) => {
    const { user, authenticated, currentPath, logoutuser } = props;
    const [currentMenuItem, setCurrentMenuItem] = useState(null);

    const [sidebar, setSidebar] = useState("");
    const handleClick = (e, item, item1, item3) => {
        const div = document.querySelector(`#${item}`);
        const ulDiv = document.querySelector(`.${item1}`);
        if (div && ulDiv) {
            e?.target?.className ? ulDiv.style.display = 'none' : ulDiv.style.display = 'block'
            e?.target?.className ? div.classList.remove('subdrop') : div.classList.add('subdrop');
        }
    }

    const navigate = useNavigate();

    useEffect(() => {
        if (!authenticated) {
            navigate('/');
        }
    }, [user, authenticated]);

    useEffect(() => {
        if (props?.id && props?.id1) {
            const ele = document.getElementById(`${props?.id}`);
            handleClick(ele, props?.id, props?.id1);
        }
    }, []);

    useEffect(() => {
        if (currentMenuItem) {
            if (!currentMenuItem.enabledForRoles.includes(user.role)) {
                navigate('/');
            }
        }
    }, [currentMenuItem]);

    const checkRole = (menuData) => {
        for (let i = 0; i < menuData.length; i++) {
            if (menuData[i].hasSubmenu) {
                checkRole(menuData[i].subMenu);
            } else if (menuData[i].url === currentPath) {
                setCurrentMenuItem(menuData[i]);
                break;
            }
        }
    }

    useEffect(() => {
        if (currentPath) {
            checkRole(menuItems);
        }
    }, []);

    const expandMenu = () => {
        document.body.classList.remove("expand-menu");
    };
    const expandMenuOpen = () => {
        document.body.classList.add("expand-menu");
    };

    const MenuItem = ({ item, itemIndex, setCurrentMenuItem, currentPath, user }) => {
        if (currentPath === item.url) {
            // setCurrentMenuItem(item);
            /*if(!item.enabledForRoles.includes(user.role)) {
                navigate('/');
            }*/
        }

        if (!item.enabledForRoles.includes('all')) {
            if (!item.enabledForRoles.includes(user.role)) {
                return null;
            }
        }

        return <>
            {item.hasSubmenu && (
                <>
                    <li className={"submenu"}>
                        <a style={{ cursor: "pointer" }} id={"menu-item" + itemIndex}
                            onClick={(e) => handleClick(e, "menu-item" + itemIndex, "menu-items" + itemIndex)}>
                            <span className="menu-side">
                                <img src={item.image} />
                            </span>
                            <span>
                                {item.text}
                            </span>{" "}
                            <span className="menu-arrow" />
                        </a>
                        <ul className={"menu-items" + itemIndex} style={{ display: "none", }}>
                            {item.subMenu.map((subItem, subItemIndex) => (
                                <MenuItem key={subItemIndex} itemIndex={itemIndex} item={subItem} setCurrentMenuItem={setCurrentMenuItem} currentPath={currentPath} user={user} />
                            ))}
                        </ul>
                    </li>
                </>
            )}
            {!item.hasSubmenu && (
                <>
                    <li>
                        <Link className={currentPath === item.url ? 'active' : ''} to={item.url}>
                            <span> {item.text} </span>
                        </Link>
                    </li>
                </>
            )}
        </>;
    };

    const Menu = ({ data, setCurrentMenuItem, currentPath, user }) => {
        return (
            <ul>
                {data.map((item, index) => (
                    <MenuItem key={index} itemIndex={index} item={item} setCurrentMenuItem={setCurrentMenuItem} currentPath={currentPath} user={user} />
                ))}
            </ul>
        );
    };

    return (
        <>
            {user &&
                <div className="sidebar" id="sidebar">
                    <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax="95vh"
                        thumbMinSize={30}
                        universal={false}
                        hideTracksWhenNotNeeded={true}
                    >
                        <div className="sidebar-inner slimscroll">
            <div
                id="sidebar-menu"
                className="sidebar-menu"
                onMouseLeave={() => document.querySelector('.logout-btn').classList.remove('expanded')}
                onMouseOver={() => document.querySelector('.logout-btn').classList.add('expanded')}
            >
                <Menu
                    data={menuItems}
                    setCurrentMenuItem={setCurrentMenuItem}
                    currentPath={currentPath}
                    user={user}
                />
                {/* Logout */}
                <div className={`logout-btn collapsed`}> {/* Initially set as collapsed */}
                    <a
                        style={{
                            cursor: "pointer",
                            padding: "12px 20px",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-start",
                            position: "relative",
                            transition: "all 0.2s ease-in-out 0s",
                            color: "#2e37a4",
                            fontWeight: "500"
                        }}
                        onClick={logoutuser}
                    >
                        <span
                            className="menu-side"
                            style={{
                                fontSize: "18px",
                                minHeight: "24px",
                                lineHeight: "18px",
                                transition: "all 0.2s ease-in-out 0s",
                                background: "rgba(46, 55, 164, 0.05)",
                                borderRadius: "8px",
                                width: "40px",
                                height: "40px",
                                marginLeft: "0",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: '10px 10px'
                            }}
                        >
                            <img src={logout} alt="Logout Icon" />
                        </span>{" "}
                        <span style={{ marginLeft: "10px" }}>Logout</span>
                    </a>
                </div>
            </div>
        </div>

                    </Scrollbars>
                </div>
            }
        </>
    )
}
const mapDispatchToProps = dispatch => ({ logoutuser: () => dispatch({ type: 'LOGOUT' }) });
export default connect(null, mapDispatchToProps)(Sidebar);
