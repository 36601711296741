/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link, useNavigate } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import clinicApi from "../../services/ClientServices/ClinicRoutes";
import { Modal } from 'bootstrap';
import usePostalCode from "../../hooks/usePostalCode";
import SuccessIcon from '../../assets/img/alert-success.256x256.png';
import DeleteIcon from "../../assets/img/delete.png";
import { connect } from "react-redux";

const AddClinic = ({ client_id }) => {
  const [error, setError] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (client_id) {
      setError(null);
    }
    else {
      setError("No client is selected, please select a client to proceed further.");
    }
  }, [client_id]);

  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    license_number: '',
    address: '',
    city: '',
    country: '',
    state: '',
    pin_code: '',
    account_holder_name: '',
    account_number: '',
    ifsc_code: '',
    bank_branch: '',
    status: '',
  });

  const {
    handlePincodeChange,
    cityOptions,
    stateOptions,
    countryOptions,
    handleCityChange,
    handleStateChange,
    handleCountryChange
  } = usePostalCode();

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
    setIsClicked(true);
  };

  const loadFile = (event) => {
    // Handle file loading logic here
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!client_id) {
      setError("No client is selected, please select a client to proceed further.");
      return;  // Prevent form submission if no clinic_id
    }

    const updatedFormData = {
      ...formData,
      pin_code: parseInt(formData.pin_code, 10) // Convert pin_code to an integer
    };

    try {
      const response = await clinicApi.createClinic(updatedFormData);

      if (response.status === 409) {
        setPopupMessage(response.message);
        setIsSuccessful(false);
      } else {
        setPopupMessage(response.message);
        setIsSuccessful(true);
      }

      setPopupMessage(response.message); // Set the message from API response

      // Show the modal with Bootstrap JavaScript
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setPopupMessage('Unknown server error occurred, Please contact support.');
      } else {
        setPopupMessage('Failed to create Clinic. Please try again.');
      }
      setIsSuccessful(false);
      // Show the modal with Bootstrap JavaScript
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    }
  };

  const handleClose = () => {
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/clinic-list');
    }
  };

  return (
    <div>
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Clinics </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Clinic</li>
                  </ul>
                </div>
              </div>
            </div>
            {error && (
              <div className="d-inline-block">
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
            )}
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Clinic Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Clinic Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="name"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Mobile <span className="login-danger">*</span>
                            </label>
                            <input
                              name="mobile"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.mobile}
                              onChange={handleChange}
                              minLength={10}
                              maxLength={10}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Email <span className="login-danger">*</span>
                            </label>
                            <input
                              name="email"
                              className="form-control"
                              type="email"
                              placeholder=""
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              License Number <span className="login-danger">*</span>
                            </label>
                            <input
                              name="license_number"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.license_number}
                              onChange={handleChange}
                              required
                            />

                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Address
                            </label>
                            <textarea
                              name="address"
                              className="form-control"
                              rows={3}
                              cols={30}
                              value={formData.address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>Postal Code</label>
                            <input
                              name="pin_code"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.pin_code}
                              minLength={6}
                              maxLength={6}
                              onChange={(e) => handlePincodeChange(e.target.value, setFormData)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>City</label>
                            <Select
                              options={cityOptions}
                              value={cityOptions.find(option => option.value === formData.city)}
                              onChange={(selectedOption) => handleCityChange(selectedOption, setFormData)}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>Country</label>
                            <Select
                              menuPosition={'fixed'}
                              menuPortalTarget={document.body}
                              options={countryOptions}
                              value={countryOptions.find(option => option.value === formData.country)}
                              onChange={(selectedOption) => handleCountryChange(selectedOption, setFormData)}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              id="search-commodity"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>State/Province</label>
                            <Select
                              menuPosition={'fixed'}
                              menuPortalTarget={document.body}
                              options={stateOptions}
                              value={stateOptions.find(option => option.value === formData.state)}
                              onChange={(selectedOption) => handleStateChange(selectedOption, setFormData)}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              id="search-commodity"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>Account Holder Name</label>
                            <input
                              name="account_holder_name"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.account_holder_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label> Account Number </label>
                            <input
                              name="account_number"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.account_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>IFSC Code</label>
                            <input
                              name="ifsc_code"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.ifsc_code}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>Bank Branch</label>
                            <input
                              name="bank_branch"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.bank_branch}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="Active"
                                  checked={formData.status === 'Active'}
                                  className="form-check-input"
                                  onChange={handleChange}
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="InActive"
                                  checked={formData.status === 'InActive'}
                                  className="form-check-input"
                                  onChange={handleChange}
                                />
                                InActive
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="reset"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* TODO -  Notifications - Box */}
        </div>
        {/* Popup box */}
        <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                {isSuccessful ? (
                  <img src={SuccessIcon} width={32} alt="Success" />
                ) : (
                  <img src={DeleteIcon} width={32} alt="Error" />
                )}
                <h3>{popupMessage}</h3>
                <div className="m-t-20">
                  {isSuccessful ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#009F6F' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#009F6F'}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#dc3545' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#CA2127'}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Popup box */}
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  client_id: state.auth.client_id
});


export  default connect(mapStateToProps)(AddClinic); 