import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const PatientByGender = ({chartData}) => {
  useEffect(() => {
    if (document.querySelector('#patient-chart')) {
      const sColStackedOptions = {
        chart: {
          height: 230,
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '15%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            name: 'Male',
            color: '#2E37A4',
            data: chartData.patient_visits_by_gender.male,
          },
          {
            name: 'Female',
            color: '#00D3C7',
            data: chartData.patient_visits_by_gender.female,
          },
        ],
        xaxis: {
          categories: chartData.patient_visits_by_gender.months,
        },
      };

      const chart = new ApexCharts(
        document.querySelector('#patient-chart'),
        sColStackedOptions
      );

      chart.render();
    }
  }, []);

  return <div id="patient-chart"></div>;
};

export default PatientByGender;
