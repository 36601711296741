import React from 'react';
import batteryIcon from "../../../assets/img/battery.svg";
import vectorIcon from '../../../assets/img/Vector.svg';
import lungsIcon from '../../../assets/img/lungs.png';
import bpIcon from "../../../assets/img/Blood_Pressure.svg";
import Cross from "../../../assets/img/x.svg";
import VitalCard from './colorRanges';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import patientApi from '../../../services/ClientServices/PatientRoutes';
import { imagesend } from '../../imagepath';
import ECGGraph from './EcgGraph';

const CardsGrid = ({ patientCards, selectPatient }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPatientForUnassign, setSelectedPatientForUnassign] = useState(null);

  const handleUnassignClick = (e, patientCard) => {
    e.stopPropagation(); // Prevent card click event from triggering
    const { patient_id, device_mac_id, clinic_id } = patientCard;
    setSelectedPatientForUnassign({ patient_id, device_mac_id, clinic_id, });
    setShowModal(true);
  };

  // API call to unassign the patient
  const handleUnassignPatient = async () => {
    const { patient_id, device_mac_id, clinic_id } = selectedPatientForUnassign;
    try {
      await patientApi.unassignPatient(device_mac_id, patient_id, clinic_id);
      window.location.reload();
    } catch (error) {
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '18px 50px' }}>
      {patientCards.map((patientCard, index) => (
        <div
          key={index}
          style={{
            backgroundColor: '#ffffff',
                    borderRadius: '8px',
                    padding: '20px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    minHeight: '270px',
                    maxHeight: '500px',
                    height: 'auto',
                    cursor: 'pointer',
          }} onClick={() => { selectPatient(patientCard); }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
            <div style={{ color: '#2e37a4', fontFamily: 'Inter', fontSize: '18px', fontWeight: 600 }}>
              {patientCard?.patient_name}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <button style={{ border: "none", background: "none" }} onClick={(e) => handleUnassignClick(e, patientCard)}>
                <img src={Cross} width={22} alt='cross' style={{ cursor: 'pointer' }} />
              </button>
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#6b7280', fontSize: '12px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <p style={{ margin: 0 }}>Floor {patientCard?.floor}</p>|<p style={{ margin: 0 }}>Room {patientCard?.room}</p>|
              <p style={{ margin: 0 }}>Bed {patientCard?.bed}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={batteryIcon} alt='' />
              <div style={{ fontSize: '12px', fontWeight: 700 }}>{patientCard?.b}
              %</div>
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ color: '#6b7280', fontFamily: 'Inter', fontSize: '12px' }}>
              Assigned Doctor: {patientCard?.doctor_name}
            </div>
            <div
              style={{
                padding: '2px 12px',
                backgroundColor:
                  patientCard?.patientDetails?.patientLevel === 1
                    ? '#FFE6E5'
                    : patientCard?.patientDetails?.patientLevel === 2
                      ? '#FFF2E5'
                      : patientCard?.patientDetails?.patientLevel === 3
                        ? '#FDECB4'
                        : 'transparent',
                color:
                  patientCard?.patientDetails?.patientLevel === 1
                    ? '#E51D00'
                    : patientCard?.patientDetails?.patientLevel === 2
                      ? '#E57300'
                      : patientCard?.patientDetails?.patientLevel === 3
                        ? '#7D6300'
                        : 'transparent',
                borderRadius: '8px',
              }}
            >
              Level {patientCard?.patientDetails?.patientLevel}
            </div>
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', paddingTop: '8px' }}>
            <div style={{ gridColumn: 'span 2', display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', borderRadius: '8px', minHeight: '50px' }}>
              <div style={{
                background: '#2455AE',
                padding: '10px',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                {/* Left section: ECG */}
                <div style={{ fontSize: '14px', color: '#ffffff' }}>
                  <span>ECG</span>
                </div>

                {/* Center section: Tachycardia */}
                {/* <div style={{ fontSize: '14px', color: '#ffffff', textAlign: 'center', flexGrow: 1 }}>
                  <span>Tachycardia</span>
                </div> */}

                {/* Right section: LIVE */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: '#ffffff', backgroundColor: "#ffffff", padding: '1px 7px', borderRadius: '9px' }}>
                  <span style={{ width: '5px', height: '5px', backgroundColor: 'red', borderRadius: '50%' }}></span>
                  <span style={{ fontSize: '12px', color: '#2455AE' }}>LIVE</span>
                </div>
              </div>

              <ECGGraph patient={patientCard} />
            </div>


            <VitalCard
              label={<img src={vectorIcon} alt='' />}
              value={(patientCard?.vitals?.vitals?.hr && patientCard?.vitals?.vitals?.hr.length > 0)
                ? patientCard.vitals.vitals.hr[patientCard.vitals.vitals.hr.length - 1]?.v
                : 'N/A'}
              type="HR"
            />

            <VitalCard
              label={<img src={lungsIcon} width={18} alt='' />}
              value={(patientCard?.vitals?.vitals?.rr && patientCard?.vitals?.vitals?.rr.length > 0)
                ? patientCard.vitals.vitals.rr[patientCard.vitals.vitals.rr.length - 1]?.v
                : 'N/A'}
              type="RR"
            />

            <VitalCard
              label={<img src='https://cdn-icons-png.flaticon.com/512/45/45844.png' width={20} alt='' />}
              value={(patientCard?.vitals?.vitals?.spo2 && patientCard?.vitals?.vitals?.spo2.length > 0)
                ? patientCard.vitals.vitals.spo2[patientCard.vitals.vitals.spo2.length - 1]?.v
                : 'N/A'}
              type="SPO2"
            />

            <VitalCard
              label={<img src='https://static.thenounproject.com/png/1979336-200.png' width={20} alt='' />}
              value={(patientCard?.vitals?.vitals?.skt && patientCard?.vitals?.vitals?.skt.length > 0) ? (patientCard?.vitals?.vitals?.skt[patientCard?.vitals?.vitals?.skt.length - 1]?.v) : 'N/A'}
              type="skt" // Skin Temperature (converted to appropriate unit)
            />

            {/* SBP */}
            <VitalCard
              label={<img src={bpIcon} width={24} alt='' />}
              value={(patientCard?.vitals?.vitals?.bp && patientCard?.vitals?.vitals?.bp.length > 0) ? patientCard?.vitals?.vitals?.bp[patientCard?.vitals?.vitals?.bp.length - 1]?.sp : 'N/A'}
              type="SBP"
            />
            <VitalCard
              label={<img src={bpIcon} width={24} alt='' />}
              value={(patientCard?.vitals?.vitals?.bp && patientCard?.vitals?.vitals?.bp.length > 0) ? patientCard?.vitals?.vitals?.bp[patientCard?.vitals?.vitals?.bp.length - 1]?.dp : 'N/A'}
              type="DBP"
            />

          </div>
        </div>
      ))}
      {/* Modal for unassign confirmation */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body className="text-center">
          <img src={imagesend} alt="" width={35} height={29} style={{ marginBottom: '10px' }} />
          <h4 style={{ margin: '10px' }} >Are you sure you want to UnAssign this Patient?</h4>
          <div className="m-t-20">
            <button className="btn btn-white me-2" onClick={() => setShowModal(false)}>
              Close
            </button>
            <button onClick={handleUnassignPatient} className="btn btn-danger">
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CardsGrid;