// src/services/ClientServices/NurseRoutes.js
import apiClient from '../apiservice';
import getClinicId from '../getClinicId';

const nurseApi = {
    addNurse: async (nurseData) => {
        try {
            const { clinic_id } = getClinicId(); // Get the dynamic clinic_id
            const response = await apiClient.post(`/clinics/${clinic_id}/add_nurse`, nurseData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getNurses: async () => {
        try {
            const { clinic_id } = getClinicId();
            const response = await apiClient.get(`/clinics/${clinic_id}/get_all_nurses`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    readNurse: async (nurse_id) => {
        try {
            const { clinic_id } = getClinicId();
            const response = await apiClient.get(`/clinics/${clinic_id}/nurses/${nurse_id}/get_nurse`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    updateNurse: async (nurse_id, nurseData) => {
        try {
            const { clinic_id } = getClinicId();
            const response = await apiClient.put(`/clinics/${clinic_id}/nurses/${nurse_id}/update`, nurseData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

export default nurseApi;
