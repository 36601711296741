import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import DonutChart from "./DonutChart";
import PatientChart from "./PatientChart";
import Select from "react-select";
import { calendar, empty_wallet, morning_img_01, profile_add, scissor, user001, invoice_icon1, invoice_icon2, invoice_icon3 } from "../../imagepath";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import {dashboard} from "../../../services/dahsboard/dashboard";
import {connect} from "react-redux";

const DeepFacts_Dashboard = ({user, authenticated,token, clinic_id, client_id}) => {
  const [selectedOption, setSelectedOption] = useState(null);


  const [dashboardData, setDashboardData] = useState({});
  const [showPage, setShowPage] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [year, setyear] = useState([
    /*{ value: 1, label: "2022" },
    { value: 2, label: "2021" },
    { value: 3, label: "2020" },
    { value: 4, label: "2019" },*/
  ]);

  const fetchData = async () => {
    const resp = await dashboard.getAdminDashboardData(user, user.user_id);
    if (resp) {
      setDashboardData(resp);
      setShowPage(true);
    }
  };

  useEffect(() => {
    if(user.user_id) {
      fetchData();
    }
  }, [user.id]);

  return (
    <>
      {(showPage &&
        <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">DeepFacts Dashboard</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      Welcome back, <span>{user.role === 'DeepFactsSuperAdmin' ? "Vamsi Karatam!" : user?.name}</span>
                    </h2>
                    {/*<p>Have a nice day at work</p>*/}
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_01}
                      alt="#" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={calendar} alt="#" />
                  </div>
                  <div className="dash-content dash-count flex-grow-1">
                    <h4>Total Clients</h4>
                    <h2>
                      {" "}
                      <CountUp delay={0.4} end={dashboardData.total_clients} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1" >
                          <FeatherIcon icon="arrow-up-right" />
                        </i>
                        40%
                      </span>{" "}
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={profile_add} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Total Clinics</h4>
                    <h2>
                      <CountUp delay={0.4} end={dashboardData.total_clinics} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right" />
                        </i>
                        20%
                      </span>{" "}
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={scissor} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4> Total Devices</h4>
                    <h2>
                      <CountUp delay={0.4} end={dashboardData.total_devices} duration={0.6} />
                    </h2>
                    <p>
                      <span className="negative-view">
                        <i className="feather-arrow-down-right me-1">
                          <FeatherIcon icon="arrow-down-right" />
                        </i>
                        15%
                      </span>{" "}
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={empty_wallet} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Total Patients</h4>
                    <h2>
                      {/* $ */}<CountUp delay={0.4} end={dashboardData.total_patients} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right" />
                        </i>
                        30%
                      </span>{" "}
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6 col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <div className="chart-title patient-visit">
                      <h4>Patients Visit by Gender</h4>
                      <div>
                        <ul className="nav chat-user-total">
                          <li>
                            <i
                              className="fa fa-circle current-users"
                              aria-hidden="true"
                            />
                            Male {dashboardData.patient_visits_by_gender.male_percent}%
                          </li>
                          <li>
                            <i
                              className="fa fa-circle old-users"
                              aria-hidden="true"
                            />{" "}
                            Female {dashboardData.patient_visits_by_gender.female_percent}%
                          </li>
                        </ul>
                      </div>
                      <div className="form-group mb-0">
                        <Select
                          className="custom-react-select"
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={year}
                          id="search-commodity"
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                              boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                              '&:hover': {
                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                              },
                              borderRadius: '10px',
                              fontSize: "14px",
                              minHeight: "45px",
                            }),
                            dropdownIndicator: (base, state) => ({
                              ...base,
                              transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                              transition: '250ms',
                              width: '35px',
                              height: '35px',
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div id="patient-chart" />
                    <PatientChart chartData={dashboardData.patient_visits_by_gender} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6 col-xl-3 d-flex">
                <div className="card">
                  <div className="card-body">
                    <div className="chart-title">
                      <h4>Devices Category</h4>
                    </div>
                    <div id="donut-chart-dash" className="chart-user-icon">
                      <DonutChart chartData={dashboardData.device_category} />
                      <img src={user001} alt="#" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card inovices-card">
                  <div className="card-body">
                    <div className="inovices-widget-header">
                      <span className="inovices-widget-icon">
                        <img src={invoice_icon1} alt={"#"} style={{ color: 'red' }} />
                      </span>
                      <div className="inovices-dash-count">
                        <div className="inovices-amount">${dashboardData.report_values.fifteen_min}</div>
                      </div>
                    </div>
                    <p className="inovices-all">Reports Generated</p>
                    <p>
                      <span style={{ color: "#00d3c7" }} className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right" />
                        </i>
                        {dashboardData.reports_generated.fifteen_min}%
                      </span>{" "}
                      <span style={{ marginBottom: 0, fontSize: "14px", fontWeight: 500, color: "#33344880" }}>
                        {" "}vs 15 mins
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card inovices-card">
                  <div className="card-body">
                    <div className="inovices-widget-header">
                      <span className="inovices-widget-icon">
                        <img src={invoice_icon2} alt={""} />
                      </span>
                      <div className="inovices-dash-count">
                        <div className="inovices-amount">${dashboardData.report_values.twenty_four_hr}</div>
                      </div>
                    </div>
                    <p className="inovices-all">Reports Generated </p>
                    <p>
                      <span style={{ color: "#00d3c7" }} className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right" />
                        </i>
                        {dashboardData.reports_generated.twenty_four_hr}%
                      </span>{" "}
                      <span style={{ marginBottom: 0, fontSize: "14px", fontWeight: 500, color: "#33344880" }}>
                        {" "}vs 24 hours
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card inovices-card">
                  <div className="card-body">
                    <div className="inovices-widget-header">
                      <span className="inovices-widget-icon">
                        <img src={invoice_icon3} alt={""} />
                      </span>
                      <div className="inovices-dash-count">
                        <div className="inovices-amount">${dashboardData.report_values.fifteen_min+dashboardData.report_values.twenty_four_hr}</div>
                      </div>
                    </div>
                    <p className="inovices-all">Total Report Value</p>
                    <p>
                      <span style={{ color: "#00d3c7" }} className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right" />
                        </i>
                        {(dashboardData.reports_generated.twenty_four_hr+dashboardData.reports_generated.fifteen_min)/2}%
                      </span>{" "}
                      <span style={{ marginBottom: 0, fontSize: "14px", fontWeight: 500, color: "#33344880" }}>
                        {" "}vs 24 hours + 15 mins
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            </div>
          </div>
        </div>
      </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.userData,
  authenticated: state.auth.authenticated,
  token: state.auth.token,
  client_id: state.auth.client_id,
  clinic_id: state.auth.clinic_id
});
export default connect(mapStateToProps)(DeepFacts_Dashboard);
