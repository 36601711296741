import React, { useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Localization from "./components/settings/Localization";
import Paymentsetting from "./components/settings/Paymentsetting";
import Settingsemail from "./components/settings/Settingsemail";
import Settingssocialmedia from "./components/settings/Settingssocialmedia";
import Settingssociallinks from "./components/settings/Settingssociallinks";
import Settingsseo from "./components/settings/Settingsseo";
import SettingsThem from "./components/settings/SettingsThem";
import SettingsChangePassword from "./components/settings/SettingsChangePassword";
import SettingsOthers from "./components/settings/SettingsOthers";
//Client
import AddClient from "./components/client/AddClient";
import ClientsList from "./components/client/ClientList";
import EditClient from "./components/client/EditClient";
import ClientProfile from "./components/client/ClientProfile";
//Clinic
import AddClinic from "./components/clinic/AddClinic";
import ClinicsList from "./components/clinic/ClinicList";
import EditClinic from "./components/clinic/EditClinic";
import ClinicProfile from "./components/clinic/ClinicProfile";
//Doctor
import DoctorList from "./components/doctor/DoctorList";
import AddDoctor from "./components/doctor/AddDoctor";
import EditDoctor from "./components/doctor/EditDoctor";
import DoctorProfile from "./components/doctor/DoctorProfile";
//Nurse
import AddNurse from "./components/Nurse/AddNurse";
import NurseList from "./components/Nurse/NurseList";
import EditNurse from "./components/Nurse/EditNurse";
import NurseProfile from "./components/Nurse/NurseProfile";
//Patients...
import PatientsList from "./components/patients/PatientsList";
import AddPatients from "./components/patients/AddPatients";
import EditPatients from "./components/patients/EditPatients";
import PatientsProfile from "./components/patients/PatientsProfile";
/* Devices */
import AddDevice from "./components/device/AddDevice";
import DeviceList from "./components/device/DeviceList";
import EditDevice from "./components/device/EditDevice";
//Remote Patient Monitoring
import RpmDetails from "./components/patients/RpmDetails";
import AssignPatient from "./components/patients/AssignPatient";
/* User Activity */
import UserActivity from "./components/activity/UserActivity";
/* Pages */
import ChangePassword from "./components/pages/login/ChangePassword";
import Error from "./components/pages/login/Error";
import ServerError from "./components/pages/login/ServerError";
import Profile from "./components/pages/login/Profile";
import EditProfile from "./components/pages/login/EditProfile";
import BlankPage from "./components/pages/login/BlankPage";
/* Dashboard */
import DeepFacts_Dashboard from "./components/Dashboard/DeepFacts_Dashboard/Deepfacts_Dashboard";
import Client_Dashboard from "./components/Dashboard/Client_Dashboard/Client_Dashboard";
import Clinic_Dashboard from "./components/Dashboard/Clinic_Dashboard/Clinic_Dashboard";
import Doctor_Dashboard from "./components/Dashboard/Doctor_Dashboard/Doctor_Dashboard";
import RpmDashboard from "./components/Dashboard/Rpm_Dashboard/RpmDashboard";
import Patient_Dashboard from "./components/Dashboard/Patient_Dashboard/Patient_Dashboard";
/* Accounts */
import Edit_Provident from "./components/accounts/Edit_Provident";
import Edit_Taxes from "./components/accounts/Edit_Taxes";
import Edit_Payment from "./components/accounts/Edit_Payment";
import Setting from "./components/settings/Setting";
import Add_ProviderFund from "./components/accounts/Add_ProviderFund";
import Invoice from "./components/accounts/Invoice";
import Create_Invoice from "./components/accounts/Create_Invoice";
import Payments from "./components/accounts/Payments";
import Add_Payment from "./components/accounts/Add_Payment";
import Expenses from "./components/accounts/Expenses";
import Add_Expense from "./components/accounts/Add_Expense";
import Taxes from "./components/accounts/Taxes";
import Add_Tax from "./components/accounts/Add_Tax";
import ProvidentFund from "./components/accounts/ProvidentFund";
import Layout from "./components/layout/Layout";
// status button
import StatusButton from "./statusbutton";
import Login from "./components/pages/login";
import VerifyOtp from "./VerifyOtp";
import VerifyEmailSuccess from "./VerifyEmail";


const AppRouter = ({ user, authenticated }) => {

    useEffect(() => {
        if (authenticated) {

        }
    }, [authenticated, user]);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    {authenticated && (
                        <>
                            <Route path="/verify-otp" element={<VerifyOtp />} />
                            <Route path="/verify_email" element={<VerifyEmailSuccess />} />
                            <Route path={"/"} element={<Layout />}>
                                <Route path="/login" element={<Layout />} />
                                {/* Settings */}
                                <Route path="/settings" element={<Setting />} />
                                <Route path="/localization" element={<Localization />} />
                                <Route path="/paymentsetting" element={<Paymentsetting />} />
                                <Route path="/settingsemail" element={<Settingsemail />} />
                                <Route
                                    path="/settingssocialmedia"
                                    element={<Settingssocialmedia />}
                                />
                                <Route path="/settingssociallink" element={<Settingssociallinks />} />
                                <Route path="/settingsseo" element={<Settingsseo />} />
                                <Route path="/settingsthem" element={<SettingsThem />} />
                                <Route
                                    path="/settingschangepassword"
                                    element={<SettingsChangePassword />}
                                />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/edit-profile" element={<EditProfile />} />
                                <Route path="/changepassword" element={<ChangePassword />} />
                                <Route path="/settingsothers" element={<SettingsOthers />} />
                                {/* Client */}
                                <Route path="/add-client" element={<AddClient />} />
                                <Route path="/client-list" element={<ClientsList />} />
                                <Route path="/edit-client" element={<EditClient />} />
                                <Route path="/client-profile" element={<ClientProfile />} />
                                {/* Clinic */}
                                <Route path="/add-clinic" element={<AddClinic />} />
                                <Route path="/clinic-list" element={<ClinicsList />} />
                                <Route path="/edit-clinic" element={<EditClinic />} />
                                <Route path="/clinic-profile" element={<ClinicProfile />} />
                                {/* Doctor  */}
                                <Route path="/doctorlist" element={<DoctorList />} />
                                <Route path="/add-doctor" element={<AddDoctor />} />
                                <Route path="/editdoctor" element={<EditDoctor />} />
                                <Route path="/doctorprofile" element={<DoctorProfile />} />
                                {/* Nurse */}
                                <Route path="/nurse-list" element={<NurseList />} />
                                <Route path="/add-nurse" element={<AddNurse />} />
                                <Route path="/edit-nurse" element={<EditNurse />} />
                                <Route path="/nurse-profile" element={<NurseProfile />} />
                                {/* Patients */}
                                <Route path="/add-patient" element={<AddPatients />} />
                                <Route path="/patient-list" element={<PatientsList />} />
                                <Route path="/edit-patient" element={<EditPatients />} />
                                <Route path="/patient-profile" element={<PatientsProfile />} />
                                {/* Devices */}
                                <Route path="/add-device" element={<AddDevice />} />
                                <Route path="/device-list" element={<DeviceList />} />
                                <Route path="/edit-device" element={<EditDevice />} />
                                {/* Remote Patient Monitoring */}
                                <Route path="/RPM-details" element={<RpmDetails />} />
                                <Route path="/assign-patient" element={<AssignPatient />} />
                                {/* Accounts */}
                                <Route path="/providentfund" element={<ProvidentFund />} />
                                <Route path="/add-providerfund" element={<Add_ProviderFund />} />
                                <Route path="/invoicelist" element={<Invoice />} />
                                <Route path="/createinvoice" element={<Create_Invoice />} />
                                <Route path="/payments" element={<Payments />} />
                                <Route path="/addpayment" element={<Add_Payment />} />
                                <Route path="/expenses" element={<Expenses />} />
                                <Route path="/addexpense" element={<Add_Expense />} />
                                <Route path="/taxes" element={<Taxes />} />
                                <Route path="/edit-taxes" element={<Edit_Taxes />} />
                                <Route path="/addtax" element={<Add_Tax />} />
                                <Route path="/edit-provident" element={<Edit_Provident />} />
                                <Route path="/edit-payment" element={<Edit_Payment />} />
                                {/* Activity */}
                                <Route path="/user-activity" element={<UserActivity />} />
                                {/* Dashboard */}
                                <Route path="/deepfacts-dashboard" element={<DeepFacts_Dashboard />} />
                                <Route path="/admin-dashboard" element={<RpmDashboard />} />
                                <Route path="/clinic-dashboard" element={<Clinic_Dashboard />} />
                                <Route path="/client-dashboard" element={<Client_Dashboard />} />
                                <Route path="/doctor-dashboard" element={<Doctor_Dashboard />} />
                                <Route path="/patient-dashboard" element={<Patient_Dashboard />} />
                                {/* status button */}
                                <Route path="/status-button" element={<StatusButton status="Inactive" />} />
                                <Route path="/error" element={<Error />} />
                                <Route path="/server-error" element={<ServerError />} />
                                <Route path="/blankpage" element={<BlankPage />} />
                                <Route path="*" element={<Error />} />
                            </Route>
                        </>
                    )}
                </Routes>
            </BrowserRouter>
            <div className="sidebar-overlay"></div>
        </>
    );
};

export default AppRouter;