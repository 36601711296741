import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const RespiratoryRateChart = ({patient}) => {
    const chartRef = useRef(null);
    const intervalRef = useRef(null);
    const windowSize = 50;

    const [chart, setChart] = React.useState(null);

    const createChart = () => {
        const ctx = chartRef.current.getContext('2d');
        let chartData={labels:[], data: []};
        if(patient.vitals?.vitals.rr && patient.vitals?.vitals.rr.length>0) {
            for (let i = ((patient.vitals.vitals.rr.length>windowSize)?patient.vitals.vitals.rr.length-windowSize:0); i < patient.vitals.vitals.rr.length; i++) {
                chartData.labels.push(patient.vitals.vitals.rr[i].t);
                chartData.data.push(patient.vitals.vitals.rr[i].v);
            }
        }
        const rrChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: chartData.labels,
                datasets: [{
                    label: 'Respiratory Rate',
                    data: chartData.data,
                    borderColor: '#3E80EB',
                    backgroundColor: 'rgba(255, 54, 103, 0.2)',
                    tension: 0.4,
                    pointRadius: 0,
                    fill: false,
                }],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: true,
                    },
                },
                animation: {
                    duration: 200,
                },
            },
        });
        const meta = rrChart.getDatasetMeta(0);
        const lastPoint = meta.data[meta.data.length - 1];

        ctx.save();
        ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
        ctx.shadowBlur = 10;
        ctx.fillStyle = '#3E80EB';
        ctx.beginPath();
        ctx.arc(lastPoint.x, lastPoint.y, 25, 0, 2 * Math.PI);
        ctx.fill();
        ctx.restore();
        setChart(rrChart);
    };

    const updateChart = () => {
        let chartData={labels:[], data: []};
        if(patient.vitals.vitals.rr && patient.vitals.vitals.rr.length>0) {
            for (let i = 0; i < patient.vitals.vitals.rr.length; i++) {
                chartData.labels.push(patient.vitals.vitals.rr[i].t);
                chartData.data.push(patient.vitals.vitals.rr[i].v);
            }
        }
        chart.data.labels=chartData.labels;
        chart.data.datasets[0].data=chartData.data;
        if (chart.data.labels.length >= windowSize) {
            chart.data.labels.splice(0,(chartData.labels.length - windowSize));
            chart.data.datasets[0].data.splice(0,(chartData.labels.length - windowSize));
        }
        chart.update();
    };

    useEffect(() => {
        if (patient && chartRef.current) {
            createChart();
        }

        return () => {
            if (chart) {
                chart.destroy();
            }
        }
    }, []);

    useEffect(() => {
        if (chart) {
            updateChart();
        }
    }, [patient.vitals?.vitals.rr]);

    return <canvas id="respiratory-rate" width={695} height={180} style={{ display: 'block', boxSizing: 'border-box', height: '180px', width: '695px', marginTop: '20px', }} ref={chartRef}></canvas>;

};

export default RespiratoryRateChart;
