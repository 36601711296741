import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import Chart from 'chart.js/auto';


const TemperatureChart = ({patient}) => {
  const chartRef = useRef(null);
  const intervalRef = useRef(null);
  const windowSize = 50;

  const [chart, setChart] = React.useState(null);

  const createChart = () => {
    const ctx = chartRef.current.getContext('2d');
    let chartData={labels:[], data: []};
    if(patient.vitals?.vitals.skt && patient.vitals?.vitals.skt.length>0) {
      for (let i = ((patient.vitals.vitals.skt.length>windowSize)?patient.vitals.vitals.skt.length-windowSize:0); i < patient.vitals.vitals.skt.length; i++) {
        chartData.labels.push(patient.vitals.vitals.skt[i].t);
        chartData.data.push((patient.vitals.vitals.skt[i].v));
      }
    }
    const sktChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: chartData.labels,
        datasets: [{
          label: 'Temperature',
          data: chartData.data,
          borderColor: '#2E37A4',
          backgroundColor: '#2E37A4',
        }],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: true,
          },
        },
        animation: {
          duration: 200,
        },
      },
    });
    const meta = sktChart.getDatasetMeta(0);
    const lastPoint = meta.data[meta.data.length - 1];
    ctx.save();
    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.shadowBlur = 10;
    ctx.fillStyle = '#FF3667';
    ctx.beginPath();
    ctx.arc(lastPoint.x, lastPoint.y, 25, 0, 2 * Math.PI);
    ctx.fill();
    ctx.restore();
    setChart(sktChart);
  }

  useEffect(() => {
    if (patient && chartRef.current) {
      createChart();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    }
  }, []);

  const updateChart = () => {
    if (chart) {
      let chartData={labels:[], data: []};
      if(patient.vitals.vitals.skt && patient.vitals.vitals.skt.length>0) {
        for (let i = ((patient.vitals.vitals.skt.length>windowSize)?patient.vitals.vitals.skt.length-windowSize:0); i < patient.vitals.vitals.skt.length; i++) {
          chartData.labels.push(patient.vitals.vitals.skt[i].t);
          chartData.data.push((patient.vitals.vitals.skt[i].v));
        }
      }

      chart.data.labels=chartData.labels;
      chart.data.datasets[0].data=chartData.data;
      if (chart.data.labels.length >= windowSize) {
        chart.data.labels.splice(0,(chartData.labels.length - windowSize));
        chart.data.datasets[0].data.splice(0,(chartData.labels.length - windowSize));
      }
      chart.update();
    }
  };

  useEffect(() => {
    if(chart) {
      updateChart();
    }
  }, [patient.vitals?.vitals.skt]);

  return <canvas id="temperature-chart" className='chartContainer' ref={chartRef}></canvas>;

};

export default TemperatureChart;
