// src/components/EditDevice.js
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import deviceApi from '../../services/DeepFactsServices/DeviceRoutes';
import { useDevice } from '../../context/DeviceContext';
import FeatherIcon from 'feather-icons-react';
import { Modal } from "bootstrap";

const EditDevice = () => {
  const { deviceId } = useDevice(); // Get deviceId from context
  const [popupMessage, setPopupMessage] = useState('');

  const [formData, setFormData] = useState({
    device_model: '',
    device_version: '',
    device_mac_id: '',
    firmware_version: '',
    manufacturer_name: '',
    production_batch_number: '',
    manufacturing_date: '',
    status: '',
  });

  useEffect(() => {
    const fetchDeviceData = async () => {
      try {
        const response = await deviceApi.readDevice(deviceId);
        console.log('Fetched device data:', response);

        if (response.devices && response.devices.length > 0) {
          const device = response.devices[0];
          setFormData({
            device_model: device.device_model || '',
            device_version: device.device_version || '',
            device_mac_id: device.device_mac_id || '',
            firmware_version: device.firmware_version || '',
            manufacturer_name: device.manufacturer_name || '',
            production_batch_number: device.production_batch_number || '',
            manufacturing_date: device.manufacturing_date || '',
            status: device.status || '',
          });
        } else {
          console.error('No device data found.');
        }
      } catch (error) {
        console.error('Failed to load device data:', error);
      }
    };

    if (deviceId) {
      fetchDeviceData();
    }
  }, [deviceId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      manufacturing_date: date ? moment(date).format('YYYY-MM-DD') : '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting device update:', formData);
      const response = await deviceApi.updateDevice(deviceId, formData);
      console.log('Device updated successfully:', response);
      setPopupMessage(response.message);
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    } catch (error) {
      console.error('Error updating device:', error);
      setPopupMessage('Failed to update device. Please try again.');
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    }
  };

  return (
    <>
      {/* <Header />
      <Sidebar id="menu-item6" id1="menu-items6" activeClassName="edit-device" /> */}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Devices</a>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Edit Device</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-heading">
                          <h4>Device Details</h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Device Model <span className="login-danger">*</span>
                          </label>
                          <input
                            name="device_model"
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={formData.device_model}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Device Version <span className="login-danger">*</span>
                          </label>
                          <input
                            name="device_version"
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={formData.device_version}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Device MacID <span className="login-danger">*</span>
                          </label>
                          <input
                            name="device_mac_id"
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={formData.device_mac_id}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Firmware Version <span className="login-danger">*</span>
                          </label>
                          <input
                            name="firmware_version"
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={formData.firmware_version}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Manufacturer Name <span className="login-danger">*</span>
                          </label>
                          <input
                            name="manufacturer_name"
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={formData.manufacturer_name}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Manufacturing Date <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            value={formData.manufacturing_date}
                            onChange={handleChange}
                            dateFormat="yyyy-MM-dd"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Production Batch Number <span className="login-danger">*</span>
                          </label>
                          <input
                            name="production_batch_number"
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={formData.production_batch_number}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group select-gender">
                          <label className="gen-label">
                            Status <span className="login-danger">*</span>
                          </label>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="status"
                                value="Inventory"
                                className="form-check-input"
                                checked={formData.status === 'Inventory'}
                                onChange={handleChange}
                              />
                              Inventory
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="status"
                                value="Active"
                                className="form-check-input"
                                checked={formData.status === 'Active'}
                                onChange={handleChange}
                              />
                              Active
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="status"
                                value="InActive"
                                className="form-check-input"
                                checked={formData.status === 'InActive'}
                                onChange={handleChange}
                              />
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button type="submit" className="btn btn-primary submit-form me-2">
                            Update
                          </button>
                          <button type="submit" className="btn btn-primary cancel-form">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popup message */}
      <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h3>{popupMessage}</h3> {/* Display API message here */}
              <div className="m-t-20">
                <button
                  type="button"
                  className="btn btn-white me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDevice;
