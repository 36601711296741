import { combineReducers, createStore } from 'redux';
import storageUtil from "../storageUtil";
import { config } from "../../config/config";
import { jwtDecode } from "jwt-decode";
import { validateTokenData } from "../validateToken";

const initialState = {
    userData: {},
    token: null,
    authenticated: false,
    clinic_id: null,
    client_id: null,
};


const auth = (state = { ...initialState }, action) => {
    let newState = { ...state }; // Start with current state
    switch (action.type) {
        case 'LOGIN':
            storageUtil.setToStore(config.tokenKey, action.data.access_token);
            const decodedData = jwtDecode(action.data.access_token);
            if (decodedData && validateTokenData(decodedData)) {
                // Check for ClinicAdmin, Doctor, or Nurse roles
                const isRelevantRole = ["ClinicAdmin", "Doctor", "Nurse", "Patient"].includes(decodedData.role);
                newState = {
                    ...state,
                    token: action.data.access_token,
                    userData: decodedData,
                    authenticated: true,
                    clinic_id: isRelevantRole ? decodedData.clinic_id : null,
                    client_id: decodedData.client_id || null,  // Store client_id
                };
                console.log('New State after LOGIN:', newState); // Log the new state
                window.location.href = '/';
                return newState;
            }
            return newState; // Use newState here
        case 'LOGOUT':
            storageUtil.removeFromStore(config.tokenKey);
            newState = { ...state, token: null, userData: null, authenticated: false };
            window.location.href = '/login';
            return newState;
        case 'CHECKTOKEN':
            const data = storageUtil.getFromStore(config.tokenKey);
            if (!data) {
                console.log('No token found');
                return newState;
            }
            const userData = jwtDecode(data);
            if (userData && validateTokenData(userData)) {
                // Check for ClinicAdmin, Doctor, or Nurse roles
                const isRelevantRole = ["ClinicAdmin", "Doctor", "Nurse"].includes(userData.role);
                if (userData.role === "ClientSuperAdmin") {
                    // Store client_id for ClientSuperAdmin
                    newState = {
                        ...state,
                        token: data,
                        userData,
                        authenticated: true,
                        client_id: userData.client_id, // Add client_id for ClientSuperAdmin
                        clinic_id: null // Will be set later based on user selection
                    };
                } else {
                    // ClinicAdmin, Doctor, or Nurse flow
                    newState = {
                        ...state,
                        token: data,
                        userData,
                        authenticated: true,
                        clinic_id: isRelevantRole ? userData.clinic_id : null,
                    };
                }
            } else {
                storageUtil.removeFromStore(config.tokenKey);
                newState = { ...state, token: null, userData: null, authenticated: false };
            }
            return newState;
        case 'SET_CLINIC_ID': // Handle clinic ID updates
            return {
                ...state,
                clinic_id: action.payload,
            };
        case 'SET_CLIENT_ID':  // New case to handle client_id
            newState = { ...state, client_id: action.payload };
            return newState;
        default:
            return state;
    }
};

const error = (state = { showPopup: false, message: '' }, action) => {
    switch (action.type) {
        case 'NOTIFY':
            return { ...state, showPopup: true, message: action.data };
        case 'ERROR':
            return { ...state, showPopup: true, message: action.data };
        case 'HIDE_ERROR':
            return { ...state, showPopup: false, message: '' };
        default:
            return state;
    }
};

const appReducer = combineReducers({
    auth,
    error
});

export const store = createStore(appReducer);