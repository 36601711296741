import React, {useEffect, useRef, useState} from 'react';
import Chart from 'chart.js/auto';

const HeartRateChart = ({patient, currentRecord}) => {
  const ecgChartRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [chartData, setChartData] = useState({ labels: [], data: [] });
  const [buffer, setBuffer] = useState([]); // Queue to act as the buffer
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowSize, setWindowSize] = useState(200);
  const [plotGraph, setPlotGraph] = useState(false); // Track second data object
  const [firstEcgData, setFirstEcgData] = useState(null);
  const [nextData, setEcgData] = useState(null);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  let plottedIndex=0;

// console.log(patient?.vitals?.ecg_clean)


  const options = {
    animation: {
      duration: 0, // Disable animation for smoother experience
    },
    scales: {
      x: { display: false, grid: { color: 'rgba(0, 0, 0, 0.1)' } },
      y: { display: false, grid: { color: 'rgba(0, 0, 0, 0.1)' } },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const createChart = () => {
    const ctx = ecgChartRef.current.getContext('2d');
    const ecgChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [],
        datasets: [{
          label: 'Heart Rate',
          data: [],
          borderColor: '#FF3667',
          backgroundColor: 'rgba(255, 54, 103, 0.2)',
          tension: 0.4,
          pointRadius: 0,
          fill: false,
        }],
      },
      options: options,
    });

    setChart(ecgChart);
  };

  const updateChart = () => {
    if (patient?.vitals?.ecg_clean.length > 0 && patient?.vitals?.ecg_clean[plottedIndex]) {
      // const newData = buffer.shift(); // Remove and get the first item from the buffer
      const newData = patient.vitals.ecg_clean[plottedIndex];
      // Log the data being plotted
      // console.log("Plotting data: ", newData);
      // console.log("Buffer size before clearing: ", buffer.length);

      chartData.labels.push(newData.t);
      chartData.data.push(newData.e);

      if (chartData.data.length > windowSize) {
        chartData.labels.splice(0, 1);
        chartData.data.splice(0, 1);
      }

      setChartData({ ...chartData }); // Ensure state update triggers re-render

      if (chart) {
        chart.data.labels = chartData.labels;
        chart.data.datasets[0].data = chartData.data;
        chart.update();
      }

      plottedIndex++;
      currentRecord(plottedIndex);

      // Log buffer after clearing the plotted data
      // console.log("Buffer size after clearing: ", buffer.length);
    } else {
      console.log("Data completed, waiting for another set of data...");
    }
  };

  const addToBuffer = (dataArray) => {
    setBuffer(prevBuffer => {
      const updatedBuffer = [...prevBuffer, ...dataArray];
      // console.log("Data added to buffer: ", dataArray);
      // console.log("Current buffer size after addition: ", updatedBuffer.length);
      return updatedBuffer;
    });
  };

  const animateChart = () => {
    if (patient?.vitals?.ecg_clean.length > 0 && currentIndex < patient?.vitals?.ecg_clean.length) {
      updateChart();
      /*setTimeout(() => {
          setCurrentIndex(currentIndex + 1);
      }, 10);*/
    } else {
      console.log("Animation paused, buffer is empty.");
    }
  };

  useEffect(()=>{
    if(patient?.vitals?.ecg_clean.length > 0 && plotGraph){
      console.log("Plotting data...");
      setInterval(()=>{
        animateChart();
      }, 15);
    }
  }, [plotGraph]);

  useEffect(() => {
    if (patient?.vitals?.ecg_clean && patient?.vitals?.ecg_clean?.length > 0) {
      if (!initialDataLoaded) {
        // Store the first ECG data message, but don't plot it yet
        setFirstEcgData(patient.vitals.ecg_clean);
        setEcgData(patient.vitals.ecg_clean);
        setInitialDataLoaded(true);
        setPlotGraph(true);
      }/* else if(initialDataLoaded && firstEcgData && !plotGraph){
                // If we already have the first message, add it to the buffer and start plotting
                // addToBuffer(firstEcgData); // Add the first message to buffer for plotting
                setEcgData(patient.vitals.ecg_clean);
                setPlotGraph(true); // Signal to start animation
            }*/ else {
        setEcgData(patient.vitals.ecg_clean);
      }
    }
  }, [patient?.vitals?.ecg_clean]);

  useEffect(() => {
    createChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, []);

  /*useEffect(() => {
      if (plotGraph) {
          animateChart();
      }
  }, [currentIndex, plotGraph]);*/

  return (
      <div style={{
        background: '#f9fafb',
        padding: '10px',
        borderRadius: '8px',
        textAlign: 'center',
        minHeight: '100px'
      }}>
        <canvas id={"ecg-chart" + patient?.patient_id} ref={ecgChartRef}></canvas>
      </div>
  );
};

export default HeartRateChart;
