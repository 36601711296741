/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Table } from "antd";
import { onShowSizeChange, itemRender } from '../Pagination';
import {
  blogimg10, imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal, blogimg12,
  blogimg2, blogimg4, blogimg6, blogimg8, error02, dangericon
} from '../imagepath';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import deviceApi from '../../services/DeepFactsServices/DeviceRoutes';
import StatusButton from '../../statusbutton';
import { useDevice } from '../../context/DeviceContext';
import { useNavigate } from 'react-router-dom';


const DeviceList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const { setDeviceId } = useDevice();
  const [error, setError] = useState(null);

  const fetchDevices = async () => {
    try {
      const data = await deviceApi.getDevices();
      setDevices(data.devices || []);
      setFilteredDevices(data.devices || []);
      setError(null);
      setError(null);
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    fetchDevices();
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    const filtered = devices.filter((device) => {
      const searchValue = value.toLowerCase();

      return (
        device.device_model.toLowerCase().includes(searchValue) ||
        device.device_version.toLowerCase().includes(searchValue) ||
        device.device_mac_id.toLowerCase().includes(searchValue) ||
        device.firmware_version.toLowerCase().includes(searchValue) ||
        device.manufacturer_name.toLowerCase().includes(searchValue) ||
        device.status.toLowerCase().includes(searchValue)
      );
    });

    setFilteredDevices(filtered);
  };

  const handleEditClick = (id) => {
    setDeviceId(id); // Set deviceId in context
    navigate('/edit-device'); // Navigate to edit page
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Device Model",
      dataIndex: "device_model",
      sorter: (a, b) => a.device_model.localeCompare(b.device_model),
    },
    {
      title: "Device Version",
      dataIndex: "device_version",
      sorter: (a, b) => a.device_version.localeCompare(b.device_version),
    },
    {
      title: "Device Mac ID",
      dataIndex: "device_mac_id",
      sorter: (a, b) => a.device_mac_id.localeCompare(b.device_mac_id),
    },
    {
      title: "Firmware Version",
      dataIndex: "firmware_version",
      sorter: (a, b) => a.firmware_version.localeCompare(b.firmware_version),
    },
    {
      title: "Manufacturer Name",
      dataIndex: "manufacturer_name",
      sorter: (a, b) => a.manufacturer_name.localeCompare(b.manufacturer_name),
    },
    {
      title: "Manufacturing Date",
      dataIndex: "manufacturing_date",
      sorter: (a, b) => new Date(a.manufacturing_date) - new Date(b.manufacturing_date),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => <StatusButton status={status} onStatusClick={() => console.log(`Status: ${status}`)} />,
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="text-end">
          <button onClick={() => handleEditClick(record.id)} className="btn btn-primary me-2">Edit</button>
          {/* Other actions like Delete can be added here */}
        </div>
      ),
    }
  ];

  return (
    <>
      {/* <Header />
      <Sidebar id='menu-item6' id1='menu-items6' activeClassName='device-list' /> */}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Devices</a>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Device List</li>
                </ul>
              </div>
            </div>
          </div>
          {error ? (
            <div className="main-wrapper error-wrapper">
              <div className="error-box">
                <img className="img-fluid" src={error02} alt="Logo" />
                <h3>
                  <img
                    className="img-fluid mb-0"
                    src={dangericon}
                    alt="Logo"
                  />{" "}
                  Service Unavailable
                </h3>
                <p>Sorry, an error has occured, and we're working to fix the problem.</p>
                <Link to="/" className="btn btn-primary go-home">
                  Back to Home
                </Link>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Device List</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <form>
                                  <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    className="form-control"
                                    placeholder="Search here"
                                  />
                                  <button className="btn">
                                    <img
                                      src={searchnormal}
                                      alt="Search"
                                    />
                                  </button>
                                </form>
                              </div>
                              <div className="add-group">
                                <Link
                                  to="/add-device"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-primary doctor-refresh ms-2"
                                  onClick={fetchDevices}
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive doctor-list">
                      <Table
                        pagination={{
                          total: devices.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredDevices}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Modal for deletion (if needed) */}
      </div>
    </>
  );
};

export default DeviceList;
