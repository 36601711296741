import {ENDPOINT} from "../../config/endpoint";
import {invokeApi} from "../../utils/httpService";

export const dashboard = {
    getClientDashboardData: async (user, client_id) => {
        const url = ENDPOINT.clientDashboard.replace('{client_id}', client_id);
        const resp = await invokeApi(url, 'GET');
        console.log(resp);
        if(resp){
            return resp?.client_dashboard;
        } else {
            return false;
        }
    },
    getClinicDashboardData: async (user, clinic_id) => {
        const url = ENDPOINT.clinicDashboard.replace('{clinic_id}', clinic_id);
        const resp = await invokeApi(url, 'GET');
        console.log(resp);
        if(resp){
            return resp?.clinic_dashboard;
        } else {
            return false;
        }
    },
    getDoctorDashboardData: async (user, doctor_id) => {
        const url = ENDPOINT.doctorDashboard.replace('{doctor_id}', doctor_id);
        const resp = await invokeApi(url, 'GET');
        console.log(resp);
        if(resp){
            return resp?.doctor_dashboard;
        } else {
            return false;
        }
    },
    getAdminDashboardData: async (user, user_id) => {
        const url = ENDPOINT.deepFactsDashboard;
        const resp = await invokeApi(url, 'GET');
        console.log(resp);
        if(resp){
            return resp?.deepfacts_dashboard;
        } else {
            return false;
        }
    }
}
