import React, {useEffect, useState} from 'react'
import {dangericon, error1} from '../../imagepath';
import {Link} from 'react-router-dom';

const Error = () => {
    const [showError, setShowError] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowError(true);
        }, 3000)
    }, []);

    return <>
        {(showError) && <>
            <div className="main-wrapper error-wrapper">
                <div className="error-box">
                    <img className="img-fluid" src={error1} alt="Logo"/>
                    <h3>
                        <img
                            className="img-fluid mb-0"
                            src={dangericon}
                            alt="Logo"
                        />{" "}
                        Service Unavailable
                    </h3>
                    <p>You may have mistyped the address or the page may have moved.</p>
                    <Link to="/" className="btn btn-primary go-home">
                        Back to Home
                    </Link>
                </div>
            </div>
        </>
        }
    </>;

}

export default Error;
