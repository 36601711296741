import React from 'react';

const NurseProfile = ({ nurseData }) => {
    return (
        <form>
            <div className="card-box">
                <h3 className="card-title">Personal Details</h3>
                <div className="row">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group local-forms">
                                <label className="focus-label">Nurse Name</label>
                                <input
                                    type="text"
                                    className="form-control floating"
                                    value={`${nurseData.first_name} ${nurseData.last_name}`}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group local-forms">
                                <label className="focus-label">Mobile Number</label>
                                <input
                                    type="text"
                                    className="form-control floating"
                                    value={nurseData.mobile}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group local-forms">
                                <label className="focus-label">Email</label>
                                <input
                                    type="text"
                                    className="form-control floating"
                                    value={nurseData.email}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group local-forms">
                                <label className="focus-label">Date of Birth</label>
                                <input
                                    type="text"
                                    className="form-control floating"
                                    value={nurseData.date_of_birth}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group local-forms">
                                <label className="focus-label">Gender</label>
                                <input
                                    type="text"
                                    className="form-control floating"
                                    value={nurseData.gender}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group local-forms">
                                <label className="focus-label">Degree</label>
                                <input
                                    type="text"
                                    className="form-control floating"
                                    value={nurseData.degree}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group local-forms">
                                <label className="focus-label">License Number</label>
                                <input
                                    type="text"
                                    className="form-control floating"
                                    value={nurseData.license_number}
                                    readOnly
                                />
                            </div>
                        </div>
                       
                        <div className="col-md-6">
                            <div className="form-group local-forms">
                                <label className="focus-label">Specialities</label>
                                <input
                                    type="text"
                                    className="form-control floating"
                                    value={nurseData.specialities.join(", ")} // Display specialities as comma-separated list
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-box">
                <h3 className="card-title">Address Information</h3>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group local-forms">
                            <label className="focus-label">Address</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="4487 Snowbird Lane"
                                value={nurseData.address}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group local-forms">
                            <label className="focus-label">State</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="New York"
                                value={nurseData.state}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group local-forms">
                            <label className="focus-label">Country</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="United States"
                                value={nurseData.country}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group local-forms">
                            <label className="focus-label">Pin Code</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={nurseData.pin_code}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-box">
                <h3 className="card-title">Bank Information</h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">Account Holder Name</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Oxford University"
                                value={nurseData.account_holder_name}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">Account Number</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Computer Science"
                                value={nurseData.account_number}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">IFSC Code</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Computer Science"
                                value={nurseData.ifsc_code}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">Bank Branch</label>
                            <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Computer Science"
                                value={nurseData.bank_branch}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* You can add more sections as needed, similar to ClinicProfile */}
        </form>
    );
};

export default NurseProfile;
