import React, { useState, useEffect } from 'react';
import { imguser } from '../imagepath';

const ClientProfile = ({ clientData }) => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        hospital_name: '',
        speciality: '',
        address: '',
        city: '',
        state: '',
        pin_code: '',
        country: '',
        mobile: '',
        email: '',
        bank_branch: '',
        account_holder_name: '',
        account_number: '',
        ifsc_code: '',
        pan_number: '',
        facebook_id: '',
        twitter_id: '',
        linkedin_id: '',
        instagram_id: '',
        status: '',
    });

    useEffect(() => {
        if (clientData) {
            // Pre-fill form with client data when the component mounts
            setFormData({
                first_name: clientData.first_name || '',
                last_name: clientData.last_name || '',
                hospital_name: clientData.hospital_name || '',
                speciality: clientData.speciality || '',
                address: clientData.address || '',
                city: clientData.city || '',
                state: clientData.state || '',
                pin_code: clientData.pin_code || '',
                country: clientData.country || '',
                mobile: clientData.mobile || '',
                email: clientData.email || '',
                bank_branch: clientData.bank_branch || '',
                account_holder_name: clientData.account_holder_name || '',
                account_number: clientData.account_number || '',
                ifsc_code: clientData.ifsc_code || '',
                pan_number: clientData.pan_number || '',
                facebook_id: clientData.facebook_id || '',
                twitter_id: clientData.twitter_id || '',
                linkedin_id: clientData.linkedin_id || '',
                instagram_id: clientData.instagram_id || '',
                status: clientData.status || '',
            });
        }
    }, [clientData]);

    return (
        <form>
            <div className="card-box">
                <h3 className="card-title">Client Profile</h3>
                <div className="row">
                    <div className="col-md-12">
                        <div className="profile-img-wrap">
                            <img className="inline-block" src={imguser} alt="user" />
                            <div className="fileupload btn">
                                <span className="btn-text">edit</span>
                                <input className="upload" type="file" />
                            </div>
                        </div>
                        <div className="profile-basic">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">First Name</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            value={formData.first_name}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            value={formData.last_name}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">Mobile</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            value={formData.mobile}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">Email</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            value={formData.email}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">Hospital Name</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            value={formData.hospital_name}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group local-forms">
                                        <label className="focus-label">Speciality</label>
                                        <input
                                            type="text"
                                            className="form-control floating"
                                            value={formData.speciality}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-box">
                <h3 className="card-title">Address Information</h3>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group local-forms">
                            <label className="focus-label">Address</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={formData.address}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group local-forms">
                            <label className="focus-label">City</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={formData.city}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group local-forms">
                            <label className="focus-label">State</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={formData.state}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group local-forms">
                            <label className="focus-label">Country</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={formData.country}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group local-forms">
                            <label className="focus-label">Pin Code</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={formData.pin_code}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-box">
                <h3 className="card-title">Bank Information</h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">Account Holder Name</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={formData.account_holder_name}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">Account Number</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={formData.account_number}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">IFSC Code</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={formData.ifsc_code}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group local-forms">
                            <label className="focus-label">Bank Branch</label>
                            <input
                                type="text"
                                className="form-control floating"
                                value={formData.bank_branch}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ClientProfile;
