import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clinicApi from '../../../services/ClientServices/ClinicRoutes';
import clientApi from "../../../services/ClientServices/ClientRoutes";
import doctorApi from '../../../services/ClientServices/DoctorRoutes';
import nurseApi from '../../../services/ClientServices/NurseRoutes';
import FeatherIcon from 'feather-icons-react';
import DoctorProfile from '../../doctor/DoctorProfile';
import NurseProfile from '../../Nurse/NurseProfile';
import ClinicProfile from "../../clinic/ClinicProfile";
import ClientProfile from "../../client/ClientProfile";


const Profile = () => {
  const [clinicData, setClinicData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [doctorData, setDoctorData] = useState(null);
  const [nurseData, setNurseData] = useState(null);
  const { clinic_id, client_id, doctor_id, nurse_id, role } = useSelector((state) => state.auth.userData);

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  useEffect(() => {
    if (role === "ClinicAdmin") {
      const fetchClinicData = async () => {
        try {
          const response = await clinicApi.readClinic(clinic_id);
          setClinicData(response.clinics[0]);
        } catch (error) {
          console.error('Error fetching clinic data:', error);
        }
      };
      fetchClinicData();
    } else if (role === "ClientSuperAdmin") {
      const fetchClientData = async () => {
        try {
          const response = await clientApi.readClient(client_id);
          setClientData(response.clients[0]);
        } catch (error) {
          console.error('Error fetching client data:', error);
        }
      };
      fetchClientData();
    }
    else if (role === "Doctor") {
      const fetchDoctorData = async () => {
        try {
          const response = await doctorApi.readDoctor(doctor_id); // Fetching doctor data using clinic_id and doctor_id
          setDoctorData(response.doctors[0]); // Assuming the doctor data is in the doctors array
        } catch (error) {
          console.error('Error fetching doctor data:', error);
        }
      };
      fetchDoctorData();
    }
    else if (role === "Nurse") {
      const fetchNurseData = async () => {
        try {
          const response = await nurseApi.readNurse(nurse_id);
          setNurseData(response.nurses[0]);
        } catch (error) {
          console.error('Error fetching nurse data:', error);
        }
      };
      fetchNurseData();
    }
  }, [clinic_id, client_id, doctor_id, nurse_id, role]);

  // Loading state while fetching data
  if ((role === "ClinicAdmin" && !clinicData) || (role === "ClientSuperAdmin" && !clientData) || (role === "Doctor" && !doctorData)  || (role === "Nurse" && !nurseData)) {
    return <div>Loading...</div>; // Optionally replace with a spinner
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7 col-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="#">Settings </Link></li>
                  <li className="breadcrumb-item">
                    <FeatherIcon icon="chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">My Profile</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Render the respective form based on the role */}
          {role === "ClinicAdmin" && clinicData && <ClinicProfile clinicData={clinicData} />}
          {role === "ClientSuperAdmin" && clientData && <ClientProfile clientData={clientData} />}
          {role === "Doctor" && doctorData && <DoctorProfile doctorData={doctorData} />}
          {role === "Nurse" && nurseData && <NurseProfile nurseData={nurseData} />} {/* Add this line */}
        </div>
      </div>
    </>
  );
};

export default Profile;
