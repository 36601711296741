import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { login02 } from './components/imagepath'; 
import proRithmLogo from "../src/assets/img/proRithm.png"

const VerifyEmailSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [verificationStatus, setVerificationStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    // Extract the token from the URL
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    console.log('Token from URL:', token);

    useEffect(() => {
        const verifyEmail = async () => {
            try {

                // Update the API call to include token as a query parameter
                const response = await axios.put(`https://api.dev.deepfacts.io/api/v2/admin/verify_email?token=${token}`);

                console.log('API response:', response); // Log the entire response

                if (response.data.success) {
                    setVerificationStatus('success');
                    console.log('Email verification successful');
                } else {
                    setVerificationStatus('failed');
                    setErrorMessage(response.data.message || 'Invalid or expired verification link.');
                    console.log('Email verification failed:', response.data.message);
                }
            } catch (error) {
                setVerificationStatus('failed');
                setErrorMessage('Verification failed. Please try again later.');
                console.error('API error:', error); // Log the error
            }
        };

        if (token) {
            verifyEmail();
        } else {
            console.error('No token found in URL');
        }
    }, [token]);


    const handleRedirect = () => {
        navigate('/'); // Redirect to the desired page after successful verification
    };

    const handleBackHome = () => {
        navigate('/'); // Redirect to the home page
    };

    return (
        <div className="main-wrapper login-body">
            <div className="container-fluid px-0">
                <div className="row">
                    {/* Login logo */}
                    <div className="col-lg-6 login-wrap">
                        <div className="login-sec">
                            <div className="log-img">
                                <img className="img-fluid" src={login02} alt="Logo" />
                            </div>
                        </div>
                    </div>
                    {/* /Login logo */}
                    {/* Login Content */}
                    <div className="col-lg-6 login-wrap-bg">
                        <div className="login-wrapper">
                            <div className="loginbox">
                                <div className="login-right">
                                    <div className="login-right-wrap">
                                        <div className="account-logo">
                                            <Link to="/admin-dashboard">
                                                <img src={proRithmLogo} width={165} height={45} alt="Logo" />
                                            </Link>
                                        </div>
                                        {verificationStatus === 'loading' && <p>Verifying your email...</p>}
                                        {verificationStatus === 'success' && (
                                            <>
                                                <h3 >Your email has been successfully verified!</h3>
                                                <button
                                                    onClick={handleRedirect}
                                                    className="btn btn-primary"
                                                >
                                                    Go to Dashboard
                                                </button>
                                            </>
                                        )}
                                        {verificationStatus === 'failed' && (
                                            <h3 style={{ fontSize: '22px', paddingTop: '30px'}}>{errorMessage}</h3>
                                        )}

                                    </div>
                                    <div className="text-center" style={{ marginTop: '60px' }}>
                                        <button
                                            onClick={handleBackHome} // Add the onClick handler here
                                            className="btn btn-primary w-100 btn-lg login-btn"
                                        >
                                            Back Home
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmailSuccess;
