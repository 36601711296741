// src/services/DeepFactsServices/DeviceRoutes.js
import apiClient from '../apiservice';

const dfUserId = 1; // hardcoded for now

const deviceApi = {
  // Create a new device
  createDevice: async (deviceData) => {
    try {
      const response = await apiClient.post(`/devices/df_userid/${dfUserId}/add_device`, deviceData);
      return response.data;
    } catch (error) {
      console.error('Error creating device:', error);
      throw error;
    }
  },

   // Read the list of devices
   getDevices: async () => {
    try {
      const response = await apiClient.get('/devices/get_all_devices');
      return response.data;
    } catch (error) {
      console.error('Error fetching devices:', error);
      throw error;
    }
  },

  // Read a specific device by its ID
  readDevice: async (deviceId) => {
    try {
      const response = await apiClient.get(`/devices/${deviceId}/get_device`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching device with ID ${deviceId}:`, error);
      throw error;
    }
  },

  // Update a specific device by its ID
  updateDevice: async (deviceId, deviceData) => {
    try {
      const response = await apiClient.put(`/devices/${deviceId}/update`, deviceData);
      return response.data;
    } catch (error) {
      console.error(`Error updating device with ID ${deviceId}:`, error);
      throw error;
    }
  },
  getDevicesByStatus: async (status) => {
    try {
      const response = await apiClient.get(`/devices/status/${status}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching devices with status ${status}:`, error);
      throw error;
    }
  }
};

export default deviceApi;
