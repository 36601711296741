import AppRouter from "./appRouter";
import {useEffect} from "react";
import {connect} from "react-redux";
import {validateTokenData} from "./utils/validateToken";
import PublicRouter from "./publicRouter";

const App = ({user, authenticated, checkToken, logoutuser}) => {

    useEffect(() => {
        checkToken();
    }, []);

    useEffect(() => {

    }, [user, authenticated]);

    useEffect(() => {
        if (authenticated) {
            const intervalId = setInterval(() => {
                if (!validateTokenData(user)) {
                    clearInterval(intervalId);
                    logoutuser();
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [user, authenticated]);

    return  <>
                {(authenticated) && <><AppRouter user={user} authenticated={authenticated}/></>}
                {(!authenticated) && <><PublicRouter user={user} authenticated={authenticated}/> </>}
            </>;
}

const mapStateToProps = (state) => ({
    user: state.auth.userData,
    authenticated: state.auth.authenticated
});
const mapDispatchToProps = dispatch => ({
    checkToken: () => dispatch({type: 'CHECKTOKEN'}),
    logoutuser: () => dispatch({type: 'LOGOUT'})
});
export default connect(mapStateToProps, mapDispatchToProps)(App);