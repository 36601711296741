import React, { createContext, useContext, useState } from 'react';

// Create a context
const NurseContext = createContext();

// Create a provider component
export const NurseProvider = ({ children }) => {
  const [nurseId, setNurseId] = useState(null);

  return (
    <NurseContext.Provider value={{ nurseId, setNurseId }}>
      {children}
    </NurseContext.Provider>
  );
};

// Custom hook to use the NurseContext
export const useNurse = () => {
  return useContext(NurseContext);
};


