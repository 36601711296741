import {config} from "../config/config";
import {store} from "./redux/store";
import storageUtil from "./storageUtil";

export const invokeApi = async (url, method, data, parseJson = true, headers = null) => {
    try {
        const token = storageUtil.getFromStore(config.tokenKey);

        const requestConfig = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'charset': 'UTF-8'
            }
        };

        if(headers){
            requestConfig.headers = {...requestConfig.headers, ...headers};
        }

        if(token) {
            requestConfig.headers['Authorization'] = 'Bearer ' + token;
        }

        if (method!=='GET' && data) {
            requestConfig.body = JSON.stringify(data);
        }

        let baseURL = config.baseUrl;

        if(url.indexOf("http") === -1){
            url = baseURL+url;
        }

        let response = await fetch(url, requestConfig);
        if (response.ok) {
            if (parseJson) {
                return response.json();
            } else {
                return response.text();
            }
        } else {
            if (!response.ok) {
                const statusCode = response.status;
                const errorText = await response.text();
                let errorResponse='';
                try{
                    errorResponse = JSON.parse(errorText);
                    if(errorResponse.detail) {
                        store.dispatch({type: 'ERROR', data: errorResponse.detail});
                    } else {
                        store.dispatch({type: 'ERROR', data: 'Something went wrong'});
                    }
                }catch(e){
                    console.log(e);
                    store.dispatch({type: 'ERROR', data: 'Something went wrong'});
                }
                console.log(errorResponse, errorText);
                switch (statusCode) {
                    case 400:
                        console.error('Bad Request:', errorText);
                        break;
                    case 401:
                        console.error('Unauthorized:', errorText);
                        store.dispatch({type: 'LOGOUT'});
                        break;
                    case 403:
                        console.error('Forbidden:', errorText);
                        break;
                    case 404:
                        console.error('Not Found:', errorText);
                        break;
                    case 500:
                        console.error('Internal Server Error:', errorText);
                        break;
                    default:
                        console.error('Unknown Error:', errorText);
                }
            }
            return false;
        }
    } catch (e) {
        console.log(e);
        console.error(e);
        return false;
    }
}